import React from 'react';
import ResourceAddButton from '../components/resource-addbutton'
import DragDelete from '../components/drag-delete'
import Resource from '../components/resource';
//import { connect } from 'react-redux';
//import { addResource } from '../reducers/resources';

const ResourceList = props => {
	return (
		<div id="resource-panel">
			<DragDelete />
			<div className="scroll-area">
				{
					props.resources.map((resource, index) =>
						<Resource resource={resource} editable={true} key={resource.id} />
					)
				}
				<ResourceAddButton next={props.next} maxlength="4" />
			</div>
		</div>
	)
}

//export default connect()(ResourceList)
export default ResourceList