import React from 'react'
import { connect } from 'react-redux'
import Tag from '../components/tag'
import TagAddButton from '../components/tag-addbutton'


const TagList = ({ showing, tags, filtered, next }) => {

	const tableheaderFirstColumn = document.querySelector("table thead th:first-child")
	const styles = showing && tableheaderFirstColumn
					? { left: tableheaderFirstColumn.offsetWidth }
					: {};

	return (
		<div id="tag-panel" style={styles}>
			<div className="scroll-area">
				{
					tags.map((tag, index) =>
							<Tag tag={tag} selectable={true} editable={true} filtered={filtered} key={tag.id} />
						)
				}
				<TagAddButton next={next} maxlength="16" />
			</div>
		</div>
	)
}

export default connect()(TagList)
