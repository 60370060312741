import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { renameTaskgroup } from '../reducers/taskgroups';
import TrelloBoardButton from '../components/trelloboard-button';


const ProjectTaskgroupName = ({ taskgroup, dispatch }) => {
	const MODE_NORMAL = "mode/NORMAL"
	const MODE_EDIT_NAME = "mode/EDITNAME"


	const groupName = !taskgroup.groupName || taskgroup.groupName === ""
				? "Untitled"
				: taskgroup.groupName


	
	const [getMode, setMode] = useState(MODE_NORMAL)
	const [editName, setEditName] = useState(groupName)


	const _input = useRef(null)
	const _container = useRef(null)


	const doubleclick = (ev) => {
			setMode(MODE_EDIT_NAME)
			setTimeout(() => {
				if (_input.current) {
					_input.current.focus()	// Hack to get _input to update with reference
				}
			}, 40)
		}
	const submitname = (ev) => {
			ev.preventDefault()
			dispatch(renameTaskgroup(taskgroup.id, editName))
			setMode(MODE_NORMAL)
		}
	const changename = (ev) => {
			setEditName(ev.target.value)
		}


	if (getMode === MODE_EDIT_NAME) {
		return (
			<form key={taskgroup.id} onSubmit={submitname}
				className="title"
				style={{ left: taskgroup.nameOffsetXpx + 'px' }}>
				<input ref={_input} className="resource margin-half-top"
					placeholder="Group name"
					type="text" maxLength="30"
					value={editName}
					onChange={changename} onBlur={submitname} />
			</form>
		)		
	}



	return (
		<React.Fragment key={taskgroup.id}>
			<TrelloBoardButton taskgroup={taskgroup} />
			<span ref={_container} 
				className="title"
				onDoubleClick={doubleclick}
				title={groupName}
				style={{ marginLeft: taskgroup.nameOffsetXpx + 'px' }}
				>{groupName}</span>
		</React.Fragment>
	)
}



export default connect()(ProjectTaskgroupName)