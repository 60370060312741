import React, { useState, useRef } from 'react'
import useDragTask from '../hooks/usedragtask'
import { RESOURCE } from '../hooks/usedroptask'
import { connect } from 'react-redux'
import { renameResource, setResourceColorBg, changeSelectedState } from '../reducers/resources';
import { SliderPicker } from 'react-color';


const Resource = ({ touchstate, resource, editable, dispatch }) => {
	const MODE_NORMAL = "mode/NORMAL"
	const MODE_EDIT_NAME = "mode/EDITNAME"
	const MODE_EDIT_COLOR = "mode/EDITCOLOR"
	
	const [getMode, setMode] = useState(MODE_NORMAL)
	const [editName, setEditName] = useState(resource.name)
	const [editColor, setEditColor] = useState(resource.colorBg)


	const _input = useRef(null)
	const _container = useRef(null)


	const isEditAllowed = touchstate.isEnabled && editable



	const longclick = (ev) => {
			if (document.body.classList.contains('is-editingresourcecolor'))
				return

			document.body.classList.add('is-editingresourcecolor')
			setMode(MODE_EDIT_COLOR)
		}
	const startdrag = (ev) => {
			console.log("drag resource " + resource.id)
			
			document.body.classList.add('drag-task')
			ev.dataTransfer.setData("text/plain", "Ressource " + resource.id)
			ev.dataTransfer.setData("id", resource.id)
			ev.dataTransfer.setData("source", RESOURCE)
		}
	const enddrag = (ev) => {
			document.body.classList.remove('drag-task')
			console.log("dropped resource " + resource.id)
		}
	const click = (ev) => {
		console.log("filter resource "+resource.id)
		dispatch(changeSelectedState(resource.id))
	}

	const dragtask = useDragTask( startdrag, enddrag, RESOURCE, click, longclick, 500 )



	const filteredClassName = (!resource.selected)
								? "is-filtered"
								: ""


	if (!isEditAllowed)
		return (
			<div key={resource.id} ref={_container} 
				className={'resource ' + filteredClassName}
				style={{backgroundColor: resource.colorBg, color: resource.colorTxt}}
				onClick={click}
				>{resource.name}</div>
		)



	const doubleclick = (ev) => {
			setMode(MODE_EDIT_NAME)
			setTimeout(() => {
				if (_input.current) {
					_input.current.focus()	// Hack to get _input to update with reference
				}
			}, 40)
		}
	const submitname = (ev) => {
			ev.preventDefault()
			if (!editName.trim()) {
				return
			}
			dispatch(renameResource(resource.id, editName))
			setMode(MODE_NORMAL)
		}
	const changename = (ev) => {
			setEditName(ev.target.value)
		}


	if (getMode === MODE_EDIT_NAME) {
		return (
			<form key={resource.id} onSubmit={submitname}>
				<input ref={_input} className="resource margin-half-top"
					style={{backgroundColor: resource.colorBg, color: resource.colorTxt}}
					placeholder="Initial"
					type="text" maxLength="4"
					value={editName}
					onChange={changename} onBlur={submitname} />
			</form>
		)		
	}





	const submitcolor = (ev) => {
			console.log("setting color to "+editColor)
			dispatch(setResourceColorBg(resource.id, editColor))
			document.body.classList.remove('is-editingresourcecolor')
			setMode(MODE_NORMAL)
		}
	const changecolor = (color, ev) => {
			ev.stopPropagation()
			setEditColor(color.hex)
		}
	const change = (color, ev) => {
			ev.stopPropagation()
		}


	if (getMode === MODE_EDIT_COLOR) {
		const rect = _container.current.getBoundingClientRect()
		return (
			<React.Fragment key={resource.id}>
				<div ref={_container} className="scroll-overflow-ui-container"
					style={{ top: rect.top + 'px' }}>
					<div className="ui-popover-container"><SliderPicker 
						color={editColor}
						onChangeComplete={changecolor} onChange={change} /><button className="btn-small" onClick={submitcolor}>OK</button></div>
					<div className="resource invisible">{resource.name}</div>
				</div>
				<div className="resource"
					style={{backgroundColor: editColor, color: resource.colorTxt}}>{resource.name}</div>
			</React.Fragment>
		)		
	}



	return (
		<div key={resource.id} ref={_container} 
			className={'resource ' + filteredClassName}
			style={{backgroundColor: resource.colorBg, color: resource.colorTxt}}
			draggable
			{...dragtask}
			onDoubleClick={doubleclick}
			>{resource.name}</div>
	)
}

const mapStateToProps = ({ touchstate }, { resource, editable }) => ({
	touchstate,
	resource,
	editable
})

export default connect(mapStateToProps)(Resource)