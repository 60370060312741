import React, { useEffect, useState, memo } from 'react'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { selectProjects } from '../../reducers/projects'
import { selectSelectedTags } from '../../reducers/tags';
import { selectResources, selectSelectedResources } from '../../reducers/resources';
import { dispatchViewboxRelatedEvents } from '../../reducers/viewboxChange';
import useMouseKeyTimeout from '../../hooks/usemousekeytimeout'
import useExtendTask from '../../hooks/useextendtask'
import WeekNumbers from '../../components/week-numbers'
import ProjectList from '../../components/project-list'
import ProjectAddButton from '../../components/project-addbutton'
import ResourceList from '../../components/resource-list'
import TagList from '../../components/tag-list'
import TagMenuButton from '../../components/tag-menubutton'
import FilterState from '../../components/filter-state'
import TouchstateToggle from '../../components/touchstate-toggle'
import { fetchResourcesAPI } from "../../actions/api-resources"
import { fetchProjectsAPI } from "../../actions/api-projects"
import { fetchTagsAPI } from "../../actions/api-tags"
import { fetchTasksAPI } from "../../actions/api-tasks"
import { fetchTaskgroupsAPI } from "../../actions/api-taskgroups"

const Home = props => {	
	useEffect(() => {
		console.log("fetching projects, tasks and resources")
		props.fetchTasksAPI()
		props.fetchTaskgroupsAPI()
		props.fetchTagsAPI()
		props.fetchResourcesAPI()
		// TODO: Fetch projects after tasks and resources using promise or load all together, instead of waiting 1 second. Tasks and resources has to be loaded before projects.
		setTimeout(() => {
			props.fetchProjectsAPI()
			setTimeout(() => { props.dispatchViewboxRelatedEvents(true) }, 1000)
		}, 1000)
	}, [])


	const VIEWSTATE_NORMAL = "viewstate/NORMAL"
	const VIEWSTATE_PRESENTATION = "viewstate/PRESENTATION"
	const [viewState, setViewState] = useState(VIEWSTATE_NORMAL)

	const onTimeoutViewstate = () => setViewState(VIEWSTATE_PRESENTATION)
	const onFirstTimeoutResetViewstate = () => {
				console.log("reset view state")
				setViewState(VIEWSTATE_NORMAL)
			}
	useMouseKeyTimeout( onTimeoutViewstate, onFirstTimeoutResetViewstate, 5 )
	





	const [scrollStartCoords, setScrollStartCoords] = useState({ x: 0, y: 0 })
	
	const scrollStart = (ev) => {
			setScrollStartCoords({ x: window.scrollX, y: window.scrollY })
		}
	const scrollMove = (ev, offset) => {
			window.scrollTo(scrollStartCoords.x - offset.x, scrollStartCoords.y - offset.y)
		}
	const scrollEnd = (ev) => {
			props.dispatchViewboxRelatedEvents()
		}


	const dragScroll = useExtendTask( scrollStart, scrollEnd, scrollMove, scrollStartCoords, false, false )



	return (
		<div id="tablebody" className={(viewState === VIEWSTATE_PRESENTATION ? "presentation-state" : "normal-state") + " " + (props.touchstate.isEnabled ? "touch-enabled" : "touch-disabled")} {...dragScroll}>
			<table>
				<thead>
					<tr>
						<th>
						    <header>
								<Link to="/" className="logo"><h2>Teknologisk</h2> Sprints</Link>
								<TagMenuButton tags={props.tags} />
						    </header>
							<FilterState filter={props.filter} />
						</th>
						<WeekNumbers weeks={props.weeks} />
					</tr>
				</thead>
				<tbody>
					<tr className="spacer"><th></th><td className="bg-container">{
						props.weeks.weeks.map((week, index) => {
							return <div key={week.id} className={"empty-bg " + (week.beforenow ? "before-week" : "") + " " + (week.now ? "this-week" : "") }></div>
						} ) }</td></tr>
					
					<ProjectList projects={props.projects} filter={props.filter} />
					<ProjectAddButton projects={props.projects} />
				</tbody>
			</table>
			<TagList showing={props.tags.showingTagsMenu} tags={props.tags.tags} filtered={props.filter.isTagsFiltered} next={props.tags.latestid} />
			<ResourceList resources={props.resources.resources} next={props.resources.latestid} />
			<TouchstateToggle />
		</div>
	)
}

const mapStateToProps = ({ projects, resources, weeks, taskgroups, tasks, tags, touchstate }) => {
		const filteredTags = selectSelectedTags(tags.tags)
		const filteredResources = selectSelectedResources(resources.resources)
		const filter = {
				tags: [...filteredTags],
				resources: [...filteredResources],
				weeks: {...weeks},
				isTagsFiltered: filteredTags.length > 0 && filteredTags.length !== tags.tags.length,
				isResourcesFiltered: filteredResources.length > 0 && filteredResources.length !== resources.resources.length
			}
		const selectedprojects = selectProjects(projects, taskgroups.taskgroups, tasks.tasks, tags.tags, resources.resources, filter)
		const selectedresources = selectResources(resources)
		return ({
			weeks,
			projects: [...selectedprojects],
			resources: selectedresources,
			taskgroups: taskgroups.taskgroups,
			tasks: tasks.tasks,
			tags,
			filter,
			touchstate,
		})
	}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			changePage: () => push('/guide'),
			fetchResourcesAPI,
			fetchTasksAPI,
			fetchTaskgroupsAPI,
			fetchTagsAPI,
			fetchProjectsAPI,
			dispatchViewboxRelatedEvents,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(Home))
