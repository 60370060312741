import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'

import { getParameter } from './helpers/url'

import 'sanitize.css/sanitize.css'
import './index.css'


const target = document.querySelector('#root')

const 	w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		x = w.innerWidth || e.clientWidth || g.clientWidth,
		//y = w.innerHeight|| e.clientHeight|| g.clientHeight,
		android = navigator.appVersion.toLowerCase().indexOf('android') !== -1

const html = document.getElementsByTagName('html')[0]
html.style.cssText = "--screenwidth: "+x+"px"
if (android) {
	html.className += "android"
}


const boardid = getParameter("boardid")
if (!boardid || boardid === "") {
	const url = window.location.href + (window.location.href.indexOf('?') > 0 ? '&' : '?') +  'boardid=default'
	render(
		<div style={{padding: '2rem', textAlign: 'center'}}>
			<h1>No board ID defined</h1>
			<h3>You should add the parameter 'boardid' to the URL.</h3>
			<p>Something along the lines of</p>
			<code>{url}</code>
		</div>,
		target
	)
} else {
	console.log("Initializing board " + boardid)
	
	render(
	  <Provider store={store}>
	    <ConnectedRouter history={history}>
	      <div>
	        <App />
	      </div>
	    </ConnectedRouter>
	  </Provider>,
	  target
	)
}
