import React from 'react';
import { connect } from 'react-redux';
import Tag from '../components/tag'
import Resource from '../components/resource';
import { clearTagFilter } from '../reducers/tags'
import { clearResourceFilter } from '../reducers/resources'


const FilterState = ({ filter, dispatch }) => {
	if (!filter.isTagsFiltered && !filter.isResourcesFiltered)
		return (<div className="filter-state"></div>);
	
	const tags = filter.isTagsFiltered
					? filter.tags.map((tag, index) =>
							<Tag tag={tag} selectable={true} key={tag.id} />
						)
					: ''
	const resources = filter.isResourcesFiltered
					? filter.resources.map((resource, index) =>
							<Resource resource={resource} editable={false} key={resource.id} />
						)
					: ''
	
	const click = (ev) => {
			console.log("clear all")
			dispatch(clearTagFilter())
			dispatch(clearResourceFilter())
		}
	
	return (
		<div className="filter-state">
			<span>Filter</span>
			<button className="clearall no-ui" onClick={click}>Clear all</button>
			{ tags }
			{ resources }
		</div>
	)
}
	
export default connect()(FilterState);