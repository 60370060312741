import React, { useRef } from 'react'
import { connect } from 'react-redux'
//import { bindActionCreators } from 'redux'
import useDropTask, { TAG, PROJECT } from '../hooks/usedroptask'
import { moveProjectRelativeTo, assignTagToProject, removeTagFromProject } from '../reducers/projects'
import ProjectHeadline from '../components/project-headline';
import ProjectTaskgroupList from '../components/project-taskgroup-list'
import ProjectTask from '../components/project-task'
import ProjectTagList from '../components/project-tag-list'
import TrelloBoardButton from '../components/trelloboard-button';
import WeekDroptarget from '../components/week-droptarget'


const Project = ({ project, filter, weeks, dispatch }) => {
	const _droparea = useRef(null)

	const onDropProject = (ev) => {
			document.body.classList.remove('drag-task')
			document.body.classList.remove('drag-taskgroup')

			const type = ev.dataTransfer.getData("type")
			const id = ev.dataTransfer.getData("id")
			switch (type) {
				case PROJECT:
					const dropid = parseInt( id )
					if (dropid === project.id || _droparea.current === undefined || !project.deletable)
						return
					
					const rect = ev.target.getBoundingClientRect()
					const insidey = ev.clientY - rect.top
					const height = rect.bottom - rect.top
					const pct = insidey / height
					const shouldmovebefore = pct < .5

					dispatch(moveProjectRelativeTo(dropid, project.id, shouldmovebefore))
					break
				
				case TAG:
					const oldprojectid = parseInt( ev.dataTransfer.getData("prjid") || "-1" )
					
					if (oldprojectid === project.id)
						return

					if (!project.deletable)
						return

					if ((project.tags || []).find(t => t.id === id) !== undefined)
						return

					if (oldprojectid !== -1) {
						dispatch(removeTagFromProject(oldprojectid, id))
					}
					
					dispatch(assignTagToProject(project.id, id))
					break
				
				default: break
			}
		}

	const dropproject = useDropTask(undefined, undefined, onDropProject, [PROJECT, TAG], undefined)
	
	
	
	
	const marginAbove = .5
	const marginBelow = .5
	const marginBetween = .25
	const padding = 0
	const lineHeight = 1.75

	const unitheight = project.isCompacted
						? .25
						: lineHeight + padding
	const height = (marginAbove + ((project.maxy + 1) * (unitheight + marginBetween) - marginBetween) + marginBelow) + 'rem'

	const compactable = project.compactable ? "compactable" : ""
	
	const opacity = project.currentSavingOps > 0
					? .5 : 1
	
	const style = project.colorBg
					? { backgroundColor: project.colorBg,
						height: height, opacity: opacity }
					: { height: height, opacity: opacity }


	return (
		<tr key={project.id} className={compactable}>
			<th style={style} ref={_droparea} {...dropproject}>
				<ProjectHeadline project={project} />
				<ProjectTagList project={project} tags={project.tags} filter={filter} />
				{ project.deletable &&
					<TrelloBoardButton project={project} />
				}
			</th>
			<td key={project.id.toString()} 
				style={style}>
					<div className="bg-container">{ 
						weeks.weeks.map((week, index) =>
							<WeekDroptarget key={week.id} targettype={PROJECT} project={project} week={week} />
						)
					}</div>
					{
						project.tasks.map((task, index) =>
								<ProjectTask key={task.id} project={project} task={task} weeks={weeks} />
							)
					}
					<ProjectTaskgroupList project={project} />
			</td>
		</tr>
	)
}

const mapStateToProps = ({ weeks }, { project, filter }) => ({
	weeks,
	project,
	filter
})

export default connect(mapStateToProps)(Project)
