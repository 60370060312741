import React from 'react'
import { connect } from 'react-redux'
import ProjectTaskgroup from '../components/project-taskgroup'


const ProjectTaskgroupList = ({ project }) => {
	return (
		project.taskgroups.map((taskgroup, index) =>
				<ProjectTaskgroup key={taskgroup.id} project={project} taskgroup={taskgroup} />
			)
	)
}

export default connect()(ProjectTaskgroupList)
