import React, { useState } from 'react';
import { connect } from 'react-redux';
import useDropTask, { TAG, TASKGROUP, TASK, PROJECT, RESOURCE } from '../hooks/usedroptask';
import { deleteProject, removeTaskgroupFromProject, removeTaskFromProject, removeTasksFromAllProjects, selectTaskIdsByProject, removeTagFromProject, removeTagsFromAllProjects } from '../reducers/projects';
import { deleteTask, deleteTasks, deleteTasksByResource, selectTaskIdsByResource } from '../reducers/tasks';
import { deleteTag } from '../reducers/tags';
import { deleteResource } from '../reducers/resources';
import { deleteTaskgroup, selectTaskIdsByTaskgroup } from '../reducers/taskgroups';

const DragDelete = ({ projects, resources, taskgroups, tasks, dispatch }) => {
	
	const [validHover, setValidHover] = useState(false)
	
	const onDragOver = () =>
			setValidHover(true)

	const onDragOut = () =>
			setValidHover(false)

	const onDrop = (ev) => {
			const type = ev.dataTransfer.getData("type")
			const id = ev.dataTransfer.getData("id")

			switch (type) {
				case TAG:
					document.body.classList.remove('drag-task')
					const tagprojectid = parseInt(ev.dataTransfer.getData("prjid") || "-1")
					
					if (tagprojectid !== -1) {
						console.log("delete tag for project id "+tagprojectid)
						dispatch(removeTagFromProject(tagprojectid, id))
					} else {
						console.log("delete tag "+id)
						dispatch(removeTagsFromAllProjects([id]))
						dispatch(deleteTag(id))
					}
					break;
			
				case TASK:
					document.body.classList.remove('drag-task')
					console.log("drop task " + id + " for deletion")
					const projectid = parseInt(ev.dataTransfer.getData("prjid") || "-1")
					
					if (projectid !== -1) {
						console.log("delete task for project id "+projectid)
		
						dispatch(removeTaskFromProject(projectid, id))
						dispatch(deleteTask(id))
					}
					break
					
				case PROJECT:
					console.log("drop project " + id + " for deletion")
					const projecttaskids = selectTaskIdsByProject(projects, id)
			    	dispatch(deleteTasks(projecttaskids))
					dispatch(deleteProject(id))
					break
				
				case RESOURCE:
					console.log("drop resource " + id + " for deletion")
					const resourcetaskids = selectTaskIdsByResource(tasks, id)
			    	dispatch(removeTasksFromAllProjects(resourcetaskids))
			    	dispatch(deleteTasksByResource(id))
					dispatch(deleteResource(id))
					break
				
				case TASKGROUP:
					document.body.classList.remove('drag-taskgroup')
					console.log("drop taskgroup " + id + " for deletion")
					const grouptaskids = selectTaskIdsByTaskgroup(taskgroups, id)
			    	dispatch(deleteTasks(grouptaskids))
			    	
					const taskgroupprojectid = parseInt(ev.dataTransfer.getData("prjid") || "-1")
					if (projectid !== -1) {
						console.log("delete task group for project id "+taskgroupprojectid)
						dispatch(removeTaskgroupFromProject(taskgroupprojectid, id))
					}
					dispatch(deleteTaskgroup(id))
					break
				
				default:
					break
			}
			
			setValidHover(false)
		}

	const droptask = useDropTask( onDragOver, onDragOut, onDrop, [TAG, TASK, TASKGROUP, PROJECT, RESOURCE], tasks )

	const classes = validHover
						? "delete hover"
						: "delete"

	return (
		<a href="#nothing"
			className={classes}
			{...droptask}
			title="Slet opgave, ressource eller projekt"
			>Slet</a>
	);
}

const mapStateToProps = ({ projects, resources, taskgroups, tasks }) => ({
	projects: projects.projects,
	resources: resources.resources,
	taskgroups: taskgroups.taskgroups,
	tasks: tasks.tasks
})

export default connect(mapStateToProps)(DragDelete);