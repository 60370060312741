import { METHOD_GET } from "../middleware/api";
import { getParameter } from '../helpers/url'


export const API = "api/API"
export const API_START = "api/API_START";
export const API_END = "api/API_END";
export const ACCESS_DENIED = "api/ACCESS_DENIED";
export const API_ERROR = "api/API_ERROR";



const getBoardId = () => {
		const boardid = getParameter('boardid')
		if (!boardid || boardid === '') {
			return console.log('Error: boardid not defined in URL')
		}
		return boardid
	}

const getBaseUrl = () => {
		const parurl = getParameter('url')
		return parurl && parurl !== ''
				? parurl
				: 'https://resources.campaignshq.com'
	}

export const getUrl = (controller, pathpars) => {
		const pathend = pathpars && pathpars !== ''
					? '/' + pathpars
					: ''
		return getBaseUrl() + "/api/" + controller + "/" + getBoardId() + pathend
	}



export const apiStart = (type, data) => ({
		type: API_START,
		payload: {
			type: type,
			data: data
		}
	})

export const apiEnd = (type, data) => ({
		type: API_END,
		payload: {
			type: type,
			data: data
		}
	})

export const accessDenied = url => ({
		type: ACCESS_DENIED,
		payload: {
			url
		}
	})

export const apiError = (error, type, data) => ({
		type: API_ERROR,
		error,
		payload: {
			type: type,
			data: data
		}
	})




export const apiAction = ({
		url = "",
		method = METHOD_GET,
		data = null,
		onSuccess = () => {},
		onFailure = () => {},
		label = ""
	}) => {
		return {
			type: API,
			payload: {
				url,
				method,
				data,
				onSuccess,
				onFailure,
				label
			}
		}
	}