export const CREATE = 'dragitem/CREATE'
export const DELETE = 'dragitem/DELETE'

export const ITEMTYPE_TASK = 'dragitem/TYPETASK';
export const ITEMTYPE_RESOURCE = 'dragitem/TYPERESOURCE';


const initialState = {
	id: 0,
	itemtype: "",
	itemid: 0,
	options: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE:
		console.log('created dragitem');
		return {
			...state,
			id: state.id + 1,
			itemtype: action.itemtype,
			itemid: action.itemid,
			options: action.options || {}
		}

    case DELETE:
      return {
        ...state,
        itemtype: "",
        itemid: 0,
        options: {}
      }

    default:
      return state
  }
}


export const createDragItem = (itemtype, itemid, options) => {
  return dispatch => {
    dispatch({
      type: CREATE,
      itemtype: itemtype,
      itemid: itemid,
      options: options
    })
  }
}

export const deleteDragItem = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE,
      id: id
    })
  }
}
