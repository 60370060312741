import React, { useRef, useState } from 'react';
import useDragTask from '../hooks/usedragtask';
import { PROJECT } from '../hooks/usedroptask';
import { connect } from 'react-redux';
import { toggleCompactMode, renameProject } from '../reducers/projects'


const ProjectHeadline = ({ project, touchstate, dispatch }) => {
	//console.log("render headline")


	const MODE_NORMAL = "mode/NORMAL"
	const MODE_EDIT_NAME = "mode/EDITNAME"
	const [getMode, setMode] = useState(MODE_NORMAL)
	const [editName, setEditName] = useState(project.name)

	const _el = useRef(null)

	const startdrag = (ev) => {
			if (!touchstate.isEnabled)
				return;
				
			console.log("drag project " + project.name)
			
			ev.dataTransfer.setData("text/plain", "Project " + project.name)
			ev.dataTransfer.setData("id", project.id)
		}

	const dragtask = useDragTask( startdrag, undefined, PROJECT, undefined, undefined, 500)


	const onclick = (ev) => {
			dispatch(toggleCompactMode(project.id))
		}

	const compactedClass = project.isCompacted
				? "btn-compact is-compacted"
				: "btn-compact"


	const _input = useRef(null)

	const doubleclick = (ev) => {
			setMode(MODE_EDIT_NAME)
			setTimeout(() => {
				_input.current.focus()	// Hack to get _input to update with reference
			}, 40)
		}
	const submitname = (ev) => {
			ev.preventDefault()
			if (!editName.trim()) {
				return
			}
			dispatch(renameProject(project.id, editName))
			setMode(MODE_NORMAL)
		}
	const changename = (ev) => {
			setEditName(ev.target.value)
		}


	if (!touchstate.isEnabled) {
		return (
			<button ref={_el}
					onClick={onclick} 
					className={compactedClass}><h3
						className="project"
						>{project.name}</h3></button>
		)
	}


	if (getMode === MODE_EDIT_NAME) {
		return (
			<form onSubmit={submitname}>
				<input ref={_input} className="project margin-half-top"
					placeholder="Indtast projektnavn"
					type="text"
					value={editName}
					onChange={changename} onBlur={submitname} />
			</form>
		)		
	}


	if (project.compactable && project.deletable) {
		return (
			<button ref={_el}
					onClick={onclick} 
					className={compactedClass}><h3 draggable
						className="project"
						{...dragtask}
						onDoubleClick={doubleclick}
						>{project.name}</h3></button>
		)
	}
	
	if (project.compactable) {
		return (
			<button ref={_el}
					onClick={onclick}
					className={compactedClass}><h3 
						className="project"
						>{project.name}</h3></button>
		)
	}

	return (
		<h3 ref={_el} draggable
			className="project"
			{...dragtask}
			onDoubleClick={doubleclick}
			>{project.name}</h3>
	)
}


const mapStateToProps = ({ touchstate }, { project }) => ({
	project: project,
	touchstate: touchstate,
})

export default connect(mapStateToProps)(ProjectHeadline)