import { combineReducers } from 'redux'
import projects from './projects'
import resources from './resources'
import taskgroups from './taskgroups'
import tasks from './tasks'
import tags from './tags'
import weeks from './weeks'
import dragitem from './dragitem'
import touchstate from './touchstate'
import viewboxChange from './viewboxChange'

export default combineReducers({
  weeks,
  projects,
  taskgroups,
  tasks,
  tags,
  resources,
  dragitem,
  touchstate,
  viewboxChange,
})
