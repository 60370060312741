import { GET_ALL_REQUESTED, GET_ALL, ADD_REQUESTED, ADD, DELETE_REQUESTED, UPDATE_REQUESTED } from '../reducers/taskgroups';
import { apiAction, getUrl } from '../actions/api';
import { METHOD_POST, METHOD_DELETE, METHOD_PUT } from "../middleware/api";




export const fetchTaskgroupsAPI = () => {
	return apiAction({
		url: getUrl("taskgroups"),
		onSuccess: setTaskgroups,
		onFailure: () => console.log("Error while fetching task groups."),
		label: GET_ALL_REQUESTED
	})
}

export const setTaskgroups = (data) => {
	return {
		type: GET_ALL,
		payload: data
	}
}



export const postTaskgroupAPI = (data) => {
	return apiAction({
		url: getUrl("taskgroups"),
		method: METHOD_POST,
		data: data,
		onSuccess: setTaskgroup,
		onFailure: () => console.log("Error while posting task group."),
		label: ADD_REQUESTED
	})
}

export const setTaskgroup = (data) => {
	return {
		type: ADD,
		payload: data
	}
}


export const deleteTaskgroupAPI = (data) => {
	return apiAction({
		url: getUrl("taskgroups", data.id),
		method: METHOD_DELETE,
		data: data,
		onFailure: () => console.log("Error while deleting task group."),
		label: DELETE_REQUESTED
	})
}

export const deleteTaskgroupsAPI = (data) => {
	return apiAction({
		url: getUrl("taskgroups", "deletemultiple"),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while deleting task group."),
		label: DELETE_REQUESTED
	})
}



export const updateTaskgroupAPI = (data) => {
	return apiAction({
		url: getUrl("taskgroups", data.id),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while updating task group."),
		label: UPDATE_REQUESTED
	})
}





