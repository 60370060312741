import React from 'react';
import { connect } from 'react-redux'
import Project from '../components/project';

const ProjectList = ({ projects, weeks, filter }) => {
	return (
		projects.map((project, index) =>
			<React.Fragment key={project.id}>
				<tr className="spacer">
					<th></th>
					<td className="bg-container">
						{ weeks.weeks.map((week, index) => {
							return <div key={week.id} className={"empty-bg " + (week.beforenow ? "before-week" : "") + " " + (week.now ? "this-week" : "") }></div>
						} ) }
					</td> 
				</tr>

				<Project project={project} filter={filter} />
			</React.Fragment>
		)
	)
}

const mapStateToProps = ({ weeks }, { projects, filter }) => ({
	weeks,
	projects,
	filter
})

export default connect(mapStateToProps)(ProjectList);
