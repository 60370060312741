import { API_START, API_END, API_ERROR } from '../actions/api';
import { postProjectAPI, deleteProjectAPI, updateProjectAPI, updateProjectSortAPI, removeTaskgroupsFromProjectsAPI, removeTasksFromProjectsAPI, removeTagsFromProjectsAPI } from '../actions/api-projects';
import { selectTaskgroups, compareTaskgroups } from '../reducers/taskgroups';
import { selectTasks, compareTasks } from '../reducers/tasks';
import { selectTags, compareTags } from '../reducers/tags';
import { masonryTaskgroups, masonryTasks, getMasonryMaxY } from '../helpers/masonry-tasks';


export const GET_ALL_REQUESTED = 'project/GET_ALL_REQUESTED'
export const GET_ALL = 'project/GET_ALL'

export const ADD_REQUESTED = 'project/ADD_REQUESTED'
export const ADD = 'project/ADD'
export const DELETE_REQUESTED = 'project/DELETE_REQUESTED'
export const DELETE = 'project/DELETE'

export const UPDATE_REQUESTED = 'project/UPDATE_REQUESTED'

export const TOGGLE_COMPACT = 'project/TOGGLE_COMPACT'
export const RENAME = 'project/RENAME'
export const CHANGE_TRELLOBOARD_URL = 'project/CHANGE_TRELLOBOARD_URL'

export const ASSIGN_TASKGROUP = 'project/ASSIGN_TASKGROUP'
export const REMOVE_TASKGROUP = 'project/REMOVE_TASKGROUP'
export const REMOVE_TASKGROUPS = 'project/REMOVE_TASKGROUPS'

export const ASSIGN_TASK = 'project/ASSIGN_TASK'
export const REMOVE_TASK = 'project/REMOVE_TASK'
export const REMOVE_TASKS = 'project/REMOVE_TASKS'

export const ASSIGN_TAG = 'project/ASSIGN_TAG'
export const REMOVE_TAG = 'project/REMOVE_TAG'
export const REMOVE_TAGS = 'project/REMOVE_TAGS_BY_RESOURCE'

export const MOVE = 'project/MOVE_PROJECT'



export const createProject = (id, name, deletable = true, compactable = true, isCompacted = false, isFiltered = false, colorBg = undefined) => {
		return {
			    id: id,
				name: name,
				deletable: deletable,
				compactable: compactable,
				isCompacted: isCompacted,
				isFiltered: isFiltered,
				currentSavingOps: 0,
				taskGroups: [],
				tasks: [],
				tags: [],
				trelloBoardUrl: "",
				colorBg: colorBg,
			}
	}



const initialState = {
	latestid: 0,
	projects: [],
	sortedids: [], 
}




const stateWithUpdatedProject = (state, id, updateCallback) => {
		return  {
				...state,
				projects: state.projects.map(project => {
					if (project.id !== id) {
					    return project
					}
					
					return Object.assign({}, project, updateCallback(project))
				})
			}
	}

export default (state = initialState, action) => {
	switch (action.type) {

		case API_START:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: true
					}
				
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedProject(state, action.payload.data.id, (project) => {
							return {
								currentSavingOps: project.currentSavingOps + 1
							}
						})
				
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_END:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: false
					}
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedProject(state, action.payload.data.id, (project) => {
							return {
								currentSavingOps: project.currentSavingOps - 1
							}
						})
					
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_ERROR:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					//alert("Something went wrong while fetching. "+ action.error)
					return state
				
				case ADD_REQUESTED:
					// Remove again and clean up individual tasks
					return state
				
				case DELETE_REQUESTED:
					// Add project back, including all tasks
					return state
				
				default:
					return state
			}
		
		
		
		case GET_ALL:
			const maxid = Math.max(...action.payload.sortedids)
			return {
				...state,
				latestid: maxid,
				projects: action.payload.projects,
				sortedids: action.payload.sortedids
			}


	
		case ADD:
			return {
				...state,
				latestid: state.latestid + 1,
				projects: [...state.projects, action.project ],
				sortedids: [...state.sortedids, action.project.id ]
			}



		case DELETE:
			return {
				...state,
				projects: [
					...state.projects.filter(p => p.id !== action.id)
				],
				sortedids: [
				    ...state.sortedids.filter(pid => pid !== action.id)
				],
			}
		
		
		
		
		case TOGGLE_COMPACT:
			return stateWithUpdatedProject(state, action.id, (project) => {
					return {
						isCompacted: !project.isCompacted
					}
				})

		
		case RENAME:
			return stateWithUpdatedProject(state, action.id, (project) => {
					return {
						name: action.name,
					}
				})

		
		case CHANGE_TRELLOBOARD_URL:
			return stateWithUpdatedProject(state, action.id, (project) => {
					return {
						trelloBoardUrl: action.trelloBoardUrl,
					}
				})
		
		
		case MOVE:
			if (state.sortedids.indexOf(action.targetid) < 0) {
				return state
			}
			
			let newsortedids = [...state.sortedids.filter(pid => pid !== action.id)]
			const targetindex = newsortedids.indexOf(action.targetid) + (action.movebefore ? 0 : 1)
			newsortedids = [
					...newsortedids.slice(0, targetindex),
					action.id,
					...newsortedids.slice(targetindex)
				]
			return {
				...state,
				sortedids: newsortedids
			}
		
		// =================
		
		case ASSIGN_TASKGROUP:
			const assigntaskgroupProject = state.projects.find(p => p.id === action.id);
			const assigntaskgroups = assigntaskgroupProject ? assigntaskgroupProject.taskGroups || [] : []
			return assigntaskgroups.indexOf(action.taskgroupid) < 0
				? stateWithUpdatedProject(state, action.id, (project) => {
					return {
						taskGroups: project.taskGroups
									? [...project.taskGroups, action.taskgroupid]
									: [action.taskgroupid]
					}
				})
				: state
		
		
		case REMOVE_TASKGROUP:
			return stateWithUpdatedProject(state, action.id, (project) => {
					return {
						taskGroups: project.taskGroups.filter(tgid => tgid !== action.taskgroupid)
					}
				})
		
		
		case REMOVE_TASKGROUPS:
			return {
				...state,
				projects: state.projects.map(project => {
					return {
						...project, 
						taskGroups: project.taskGroups.filter(tgid => action.taskgroupids.indexOf(tgid) < 0)
					}
				})
			}
		
		
		// =================
		
		case ASSIGN_TASK:
			return state.projects.find(p => p.id === action.id).tasks.indexOf(action.taskid) < 0
				? stateWithUpdatedProject(state, action.id, (project) => {
					return {
						tasks: [...project.tasks, action.taskid]
					}
				})
				: state
		
		
		case REMOVE_TASK:
			return stateWithUpdatedProject(state, action.id, (project) => {
					return {
						tasks: project.tasks.filter(tid => tid !== action.taskid)
					}
				})
		
		
		case REMOVE_TASKS:
			return {
				...state,
				projects: state.projects.map(project => {
					return {
						...project, 
						tasks: project.tasks.filter(tid => action.taskids.indexOf(tid) < 0)
					}
				})
			}
		
		// =================
		
		case ASSIGN_TAG:
			const assigntagproject = state.projects.find(p => p.id === action.id)
			return assigntagproject.tags === null || assigntagproject.tags.indexOf(action.tagid) < 0
					? stateWithUpdatedProject(state, action.id, (project) => {
						return {
							tags: project.tags !== null
											? [...project.tags, action.tagid]
											: [action.tagid]
						}
					})
					: state
		
		
		case REMOVE_TAG:
			return stateWithUpdatedProject(state, action.id, (project) => {
					return {
						tags: project.tags.filter(tid => tid !== action.tagid)
					}
				})
		
		
		case REMOVE_TAGS:
			return {
				...state,
				projects: state.projects.map(project => {
					return {
						...project, 
						tags: project.tags !== null
								? project.tags.filter(tid => action.tagids.indexOf(tid) < 0)
								: []
					}
				})
			}
		
		// =================
		
		
		
		default:
			return state
	}
}

export const addProject = (name, deletable = true, compactable = true, isCompacted = false, colorBg = undefined) => {
	return (dispatch, getState) => {
		const nextid = getState().projects.latestid + 1
		const project = createProject(nextid, name, deletable, compactable, isCompacted, false, colorBg)

	    dispatch({
			type: ADD,
			project: project
	    })

		dispatch(postProjectAPI(project))
	}
}

export const deleteProject = (id) => {
	return dispatch => {
		dispatch({
			type: DELETE,
			id: parseInt( id )
		})

		dispatch(deleteProjectAPI({
			id: id
		}))
	}
}



export const toggleCompactMode = (projectid) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )
		
		dispatch({
			type: TOGGLE_COMPACT,
			id: pid
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}


export const renameProject = (projectid, name) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )
		
		dispatch({
			type: RENAME,
			id: pid,
			name: name
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}


export const changeTrelloBoardUrl = (projectid, trelloBoardUrl) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )
		
		dispatch({
			type: CHANGE_TRELLOBOARD_URL,
			id: pid,
			trelloBoardUrl: trelloBoardUrl
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}



export const moveProjectRelativeTo = (projectid, targetprojectid, shouldmovebefore) => {
	return (dispatch, getState) => {
		dispatch({
			type: MOVE,
			id: parseInt( projectid ),
			targetid: parseInt( targetprojectid ),
			movebefore: shouldmovebefore
		})
		
		const sortedids = getState().projects.sortedids
		dispatch(updateProjectSortAPI(sortedids))
	}
}





// ================


export const assignTaskToProject = (projectid, taskid) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )

		dispatch({
			type: ASSIGN_TASK,
			id: pid,
			taskid: taskid
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}


export const removeTaskFromProject = (projectid, taskid) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )

		dispatch({
			type: REMOVE_TASK,
			id: pid,
			taskid: taskid
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}

export const removeTasksFromAllProjects = (taskids) => {
	return (dispatch, getState) => {
		dispatch({
			type: REMOVE_TASKS,
			taskids: taskids
		})

		dispatch(removeTasksFromProjectsAPI(taskids))
	}
}



// ================


export const assignTaskgroupToProject = (projectid, taskgroupid) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )

		dispatch({
			type: ASSIGN_TASKGROUP,
			id: pid,
			taskgroupid: taskgroupid
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}


export const removeTaskgroupFromProject = (projectid, taskgroupid) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )

		dispatch({
			type: REMOVE_TASKGROUP,
			id: pid,
			taskgroupid: taskgroupid
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}

export const removeTaskgroupsFromAllProjects = (taskgroupids) => {
	return (dispatch, getState) => {
		dispatch({
			type: REMOVE_TASKGROUPS,
			taskgroupids: taskgroupids
		})

		dispatch(removeTaskgroupsFromProjectsAPI(taskgroupids))
	}
}


// ================


export const assignTagToProject = (projectid, tagid) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )

		dispatch({
			type: ASSIGN_TAG,
			id: pid,
			tagid: tagid
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}

export const removeTagFromProject = (projectid, tagid) => {
	return (dispatch, getState) => {
		const pid = parseInt( projectid )

		dispatch({
			type: REMOVE_TAG,
			id: pid,
			tagid: tagid
		})
		
		const project = getState().projects.projects.find(p => p.id === pid)
		dispatch(updateProjectAPI(project))
	}
}

export const removeTagsFromAllProjects = (tagids) => {
	return (dispatch, getState) => {
		dispatch({
			type: REMOVE_TAGS,
			tagids: tagids
		})

		dispatch(removeTagsFromProjectsAPI(tagids))
	}
}


// ================





export const selectTaskIdsByProject = (projects, id) => {
	const projectid = parseInt(id)
	return projects.find(p => p.id === projectid).tasks
}

export const selectProjects = (projects, statetaskgroups, statetasks, statetags, stateresources, filter) => {
	const isFiltered = filter !== undefined && (filter.isTagsFiltered || filter.isResourcesFiltered)
	const taskgroups = statetaskgroups || []
	const tasks = statetasks || []
	const tags = statetags || []
	const resources = stateresources || []
	
	let sorted = []
	projects.sortedids.forEach((pid) => {
		if (sorted.find(p => p.id === pid) !== undefined)
			return;	// If the sorted IDs is corrupted, skip
		
		const pindex = projects.projects.findIndex(p => p.id === pid)
		
		if (pindex < 0) {
			throw new Error("Database out of sync")
		}
		
		const p = Object.assign({}, projects.projects[pindex])

		const projecttags = selectTags(tags, p.tags || [])
		
		// Check if any tags are selected for showing
		if (filter.isTagsFiltered && p.deletable) {
			if (projecttags.findIndex(tag => tag.selected === true) === -1) {
				return
			}
		}

		
		projecttags.sort(compareTags)
		
		const projectFilter = p.deletable
						? filter
						: { isResourcesFiltered: false, isTagsFiltered: false, weeks: filter.weeks }	// Don't filter override
		
		const projecttaskgroups = selectTaskgroups(taskgroups, p.taskGroups, tasks, resources, projectFilter)
		const projecttasks = selectTasks(tasks, p.tasks, resources, projectFilter)
		
		if (p.deletable && filter.isResourcesFiltered && projecttaskgroups.length === 0 && projecttasks.length === 0) {
			return
		}

		projecttaskgroups.sort(compareTaskgroups)
		projecttasks.sort(compareTasks)
		

		const masonryTaskgroupObj = masonryTaskgroups(projecttaskgroups)
		const masonryTaskObj = masonryTasks(projecttasks, masonryTaskgroupObj.masonrymap)
		
		sorted.push(Object.assign({}, p, { 
			tasks: masonryTaskObj.tasks,
			taskgroups: masonryTaskgroupObj.taskgroups,
			isFiltered: isFiltered,
			maxy: getMasonryMaxY(masonryTaskgroupObj.taskgroups, masonryTaskObj.tasks),
			tags: projecttags
		}))
	})
	return sorted
}