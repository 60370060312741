import { API_START, API_END, API_ERROR } from '../actions/api';
import { postResourceAPI, deleteResourceAPI, updateResourceAPI } from '../actions/api-resources';
import { selectColor } from '../helpers/colors';

export const GET_ALL_REQUESTED = 'resource/GET_ALL_REQUESTED'
export const GET_ALL = 'resource/GET_ALL'

export const ADD_REQUESTED = 'resource/ADD_REQUESTED'
export const ADD = 'resource/ADD'

export const DELETE_REQUESTED = 'resource/DELETE_REQUESTED'
export const DELETE = 'resource/DELETE'

export const UPDATE_REQUESTED = 'resource/UPDATE_REQUESTED'

export const RENAME = 'resource/RENAME'
export const SET_COLOR_BG = 'resource/SET_COLOR_BG'

export const TOGGLE_SELECTED_STATE = 'resource/TOGGLE_SELECTED_STATE'
export const CLEAR_ALL_SELECTED_STATE = 'resource/CLEAR_ALL_SELECTED_STATE'


export const createResource = (id, name, colorBg, colorTxt) => {
		return {
			    id: id,
				name: name,
				colorBg: colorBg,
				colorTxt: colorTxt,
				selected: true,
				currentSavingOps: 0
			}
	}

const initialState = {
	latestid: 0,
	resources: [],
	isLoading: false,
	isDeleting: false,
}


const stateWithUpdatedResource = (state, id, updateCallback) => {
		return  {
				...state,
				resources: state.resources.map(resource => {
					if (resource.id !== id) {
					    return resource
					}
					
					return Object.assign({}, resource, updateCallback(resource))
				})
			}
	}



export default (state = initialState, action) => {
	switch (action.type) {
		case API_START:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: true
					}
				
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedResource(state, action.payload.data.id, (resource) => {
							return {
								currentSavingOps: (resource.currentSavingOps || 0) + 1
							}
						})
				
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_END:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: false
					}
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedResource(state, action.payload.data.id, (resource) => {
							return {
								currentSavingOps: (resource.currentSavingOps || 0) - 1
							}
						})
					
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_ERROR:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					//alert("Something went wrong while fetching. "+ action.error)
					return state
				
				case ADD_REQUESTED:
					// Remove again and clean up individual tasks
					return state
				
				case DELETE_REQUESTED:
					// Add project back, including all tasks
					return state
				
				default:
					return state
			}


		
		case GET_ALL:
			if (!action.payload.resources) {
				return state
			}
			
			const maxid = action.payload.resources.reduce((memo, r) => Math.max(memo, r.id), 0)
			const resourcesWithSelectedState = action.payload.resources.map(resource => {
							return Object.assign({}, resource, { selected: true })
						})
			return {
				...state,
				latestid: maxid,
				resources: resourcesWithSelectedState
			}

		
		case ADD:
			return {
				...state,
				latestid: state.latestid + 1,
				resources: [...state.resources, action.resource],
				isAdding: !state.isAdding
			}
		

		
		case DELETE:
			return {
				...state,
				resources: [
					...state.resources.filter(r => r.id !== action.id)
				],
				isDeleting: !state.isDeleting
			}


		
		case RENAME:
			const existingWithNameIndex = state.resources.findIndex(r => r.name === action.name);
			if (existingWithNameIndex >= 0)
				return state;

			return stateWithUpdatedResource(state, action.id, (resource) => {
					return {
						name: action.name
					}
				})

		case SET_COLOR_BG:
			return stateWithUpdatedResource(state, action.id, (resource) => {
					return {
						colorBg: action.color
					}
				})

		case TOGGLE_SELECTED_STATE:
			// First resource to toggle... deselect all but this
			const firstDeselectedIndex = state.resources.findIndex(r => !r.selected)
			const allSelected = firstDeselectedIndex === -1
			if (allSelected) {
				return {
						...state,
						resources: state.resources.map(resource => {
							return Object.assign({}, resource, { selected: resource.id === action.id })
						})
					}
			}
			
			// Last resource to toggle... select all
			const filtered = selectSelectedResources(state.resources)
			if (filtered.length === 1) {
				const targetresource = state.resources.find(r => r.id === action.id)
				if (targetresource && targetresource.selected) {
					return {
							...state,
							resources: state.resources.map(resource => {
								return Object.assign({}, resource, { selected: true })
							})
						}
				}
			}
			
			// Just another one... add it
			return stateWithUpdatedResource(state, action.id, (resource) => {
					return {
						selected: action.select === undefined 
									? !resource.selected		// toggle
									: action.select					// force state
					}
				})

		case CLEAR_ALL_SELECTED_STATE:
			return {
				...state,
				resources: state.resources.map(resource => {
					return Object.assign({}, resource, { selected: true })
				})
			}
		
		default:
		  return state
	}
}




export const addResource = (name, nextcolornumber) => {		
	const randomHsl = selectColor(nextcolornumber)
	return (dispatch, getState) => {
		const resources = getState().resources
		
		const index = resources.resources.findIndex(r => r.name === name)
		if (index >= 0) {
			return
		}

		const nextid = resources.latestid + 1
		const resource = createResource(nextid, name, randomHsl, "#fff")
		
		dispatch({
			type: ADD,
			resource: resource
		})
		
		dispatch(postResourceAPI(resource))
	}
}


export const deleteResource = (id) => {
	return (dispatch, getState) => {
		const rid = parseInt( id )

		dispatch({
			type: DELETE,
			id: rid
		})

		dispatch(deleteResourceAPI({
			id: id
		}))
	}
}


export const renameResource = (id, name) => {
	return (dispatch, getState) => {
		const rid = parseInt( id )

		dispatch({
			type: RENAME,
			id: rid,
			name: name
		})
		
		const resource = getState().resources.resources.find(r => r.id === rid)
		dispatch(updateResourceAPI(resource))
	}
}


export const setResourceColorBg = (id, color) => {
	return (dispatch, getState) => {
		const rid = parseInt( id )

		dispatch({
			type: SET_COLOR_BG,
			id: rid,
			color: color
		})
		
		const resource = getState().resources.resources.find(r => r.id === rid)
		dispatch(updateResourceAPI(resource))
	}
}


export const changeSelectedState = (id, select) => {
	return (dispatch, getState) => {
		dispatch({
			type: TOGGLE_SELECTED_STATE,
			id: id,
			select: select
		})
	}
}

export const clearResourceFilter = () => {
	return (dispatch, getState) => {
		dispatch({
			type: CLEAR_ALL_SELECTED_STATE,
		})
	}
}





export const compareResources = (resource1, resource2) => {
		return ('' + resource1.name).localeCompare(resource2.name)
	}


export const selectResources = (resources) => {
	resources.resources.sort(compareResources)
	return resources
}

export const selectResource = (resources, id) => {
	const resourceid = parseInt( id );
	return Object.assign({}, resources.find(r => r.id === resourceid))
}



export const selectSelectedResources = (stateresources) => {
	const filteredResources = stateresources.filter(r => r !== undefined && r.selected === true)
	return filteredResources
}

