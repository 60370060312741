import { GET_ALL_REQUESTED, GET_ALL, ADD_REQUESTED, ADD, DELETE_REQUESTED, UPDATE_REQUESTED } from '../reducers/tags'
import { apiAction, getUrl } from '../actions/api'
import { METHOD_POST, METHOD_DELETE, METHOD_PUT } from "../middleware/api"





export const fetchTagsAPI = () => {
	return apiAction({
		url: getUrl("tags"),
		onSuccess: setTags,
		onFailure: () => console.log("Error while fetching tags."),
		label: GET_ALL_REQUESTED
	})
}

export const setTags = (data) => {
	return {
		type: GET_ALL,
		payload: data
	}
}



export const postTagAPI = (data) => {
	return apiAction({
		url: getUrl("tags"),
		method: METHOD_POST,
		data: data,
		onSuccess: setTag,
		onFailure: () => console.log("Error while posting tag."),
		label: ADD_REQUESTED
	})
}

export const setTag = (data) => {
	return {
		type: ADD,
		payload: data
	}
}


export const deleteTagAPI = (data) => {
	return apiAction({
		url: getUrl("tags", data.id),
		method: METHOD_DELETE,
		data: data,
		onFailure: () => console.log("Error while deleting tag."),
		label: DELETE_REQUESTED
	})
}

export const deleteTagsAPI = (data) => {
	return apiAction({
		url: getUrl("tags", "deletemultiple"),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while deleting tag."),
		label: DELETE_REQUESTED
	})
}



export const updateTagAPI = (data) => {
	return apiAction({
		url: getUrl("tags", data.id),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while updating tag."),
		label: UPDATE_REQUESTED
	})
}





