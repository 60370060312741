export const TOGGLE = 'touchstate/TOGGLE'


const initialState = {
	isEnabled: false,
}

export default (state = initialState, action) => {
	switch (action.type) {

		case TOGGLE:
			return {
				...state,
				isEnabled: !state.isEnabled
			}
		
		default:
			return state
	}
}


export const toggleTouchstate = () => {
	return dispatch => {
		dispatch({
			type: TOGGLE
		})
	}
}


