import { useState, useEffect, useCallback } from 'react'

export default function useExtendTask( startCallback = () => {}, endCallback = () => {}, moveCallback = () => {}, watch = {}, preventPropagation = true, usetouch = true ) {	
	const [mouseMove, setMouseMove] = useState(false)
	const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 })
	
	useEffect(() => {
		const setMoveFromEvent = (ev) => {
			if (preventPropagation) {
				ev.preventDefault()
				ev.stopPropagation()
				//ev.nativeEvent.stopImmediatePropagation()
			}

			moveCallback(ev, { x: ev.clientX - mouseOffset.x, y: ev.clientY - mouseOffset.y })
		}

		const setEndFromEvent = (ev) => {
			if (mouseMove) {
				setMouseMove(false)			
				endCallback(ev)
			}
		}
		
		if (mouseMove) {
			window.addEventListener('mousemove', setMoveFromEvent)
			window.addEventListener('mouseup', setEndFromEvent)
			if (usetouch) {
				window.addEventListener('touchmove', setMoveFromEvent)
				window.addEventListener('touchend', setEndFromEvent)
			}
		} else {
			window.removeEventListener('mousemove', setMoveFromEvent)
			window.removeEventListener('mouseup', setEndFromEvent)
			if (usetouch) {
				window.removeEventListener('touchmove', setMoveFromEvent)
				window.removeEventListener('touchend', setEndFromEvent)
			}
		}
		
		return () => {
			window.removeEventListener('mousemove', setMoveFromEvent)
			window.removeEventListener('mouseup', setEndFromEvent)
			if (usetouch) {
				window.removeEventListener('touchmove', setMoveFromEvent)
				window.removeEventListener('touchend', setEndFromEvent)
			}
		}
	}, [mouseMove, watch])


	const start = useCallback((ev) => {
		if (preventPropagation) {
			ev.preventDefault()
			ev.stopPropagation()
			//ev.nativeEvent.stopImmediatePropagation()
		}
		
		setMouseOffset({ x: ev.clientX, y: ev.clientY })
		setMouseMove(true)
		startCallback(ev)
	}, [watch])

	if (usetouch) {
		return {
			onMouseDown: start,
			onTouchStart: start,
		}	
	} else {
		return {
			onMouseDown: start,
		}	
	}
}
