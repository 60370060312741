import { API_START, API_END, API_ERROR } from '../actions/api';
import { postTaskgroupAPI, deleteTaskgroupAPI, deleteTaskgroupsAPI, updateTaskgroupAPI } from '../actions/api-taskgroups';
import { selectTasks, compareTasks } from '../reducers/tasks';
import { createWeekObjArr, getIdForWeekObj, calcWeekObjForWeekDuration, calcWeeksDuration, createWeekObjForWeekAndYear, createWeekObjForWeekId, compareWeeks } from '../helpers/weeks'
import { masonryTasks, getMasonryMaxY } from '../helpers/masonry-tasks';

export const GET_ALL_REQUESTED = 'taskgroup/GET_ALL_REQUESTED'
export const GET_ALL = 'taskgroup/GET_ALL'

export const ADD_REQUESTED = 'taskgroup/ADD_REQUESTED'
export const ADD = 'taskgroup/ADD'

export const DELETE_REQUESTED = 'taskgroup/DELETE_REQUESTED'

export const DELETE = 'taskgroup/DELETE'
export const DELETE_MULTIPLE = 'taskgroup/DELETE_MULTIPLE'

export const UPDATE_REQUESTED = 'taskgroup/UPDATE_REQUESTED'


export const RENAME = 'taskgroup/RENAME'
export const SET_START = 'taskgroup/SET_START'
export const SET_DURATION = 'taskgroup/SET_DURATION'
export const CHANGE_TRELLOBOARD_URL = 'taskgroup/CHANGE_TRELLOBOARD_URL'

export const ASSIGN_TASK = 'taskgroup/ASSIGN_TASK'
export const REMOVE_TASK = 'taskgroup/REMOVE_TASK'


export const SET_FOCUS = 'taskgroup/SET_FOCUS'

export const UPDATE_NAMEOFFSET = 'taskgroup/UPDATE_NAMEOFFSET'
export const UPDATE_NAMEOFFSETS = 'taskgroup/UPDATE_NAMEOFFSETS'


export const createTaskgroup = (id, groupname, lateststartweek, lateststartyear, minimumduration) => {
		return {
				id: id,
				groupName: groupname,
				tasks: [],
				lateststartweek: lateststartweek,
				lateststartyear: lateststartyear,
				minimumDuration: minimumduration,
				trelloBoardUrl: "",
				y: 0,
				maxy: 0,
				focus: false,
				currentSavingOps: 0,
				nameOffsetXpx: 0,
			}
	}



const initialState = {
	count: 0,
	taskgroups: [],
	isDeleting: false,
	isLoading: false,
}


export function newTaskgroupCID(project) {
	const name = project.id || "_anon";
	const d = new Date();
	const dts = d.getFullYear() + ("0"+(d.getMonth()+1)).slice(-2) + ("0" + d.getDate()).slice(-2) + ("0" + d.getHours()).slice(-2) + ("0" + d.getMinutes()).slice(-2) + ("0" + d.getSeconds()).slice(-2) + ("0000" + Math.round(Math.random() * 1000)).slice(-4);
	return name + "-" + dts;
}

export function newDefaultTaskgroupName() {
	return "Ikke navngivet"
}


function stateWithUpdatedTaskgroup(state, id, updateCallback) {
	return  {
			...state,
			taskgroups: state.taskgroups.map(taskgroup => {
				if (taskgroup.id !== id) {
				    return taskgroup
				}
				
				return Object.assign({}, taskgroup, updateCallback(taskgroup))
			})
		}
}




export default (state = initialState, action) => {
	switch (action.type) {

		case API_START:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: true
					}
				
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedTaskgroup(state, action.payload.data.id, (taskgroup) => {
							return {
								currentSavingOps: (taskgroup.currentSavingOps || 0) + 1
							}
						})
				
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_END:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: false
					}
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedTaskgroup(state, action.payload.data.id, (taskgroup) => {
							return {
								currentSavingOps: (taskgroup.currentSavingOps || 0) - 1
							}
						})
					
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_ERROR:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					//alert("Something went wrong while fetching. "+ action.error)
					return state
				
				case ADD_REQUESTED:
					// Remove again and clean up individual tasks
					return state
				
				case DELETE_REQUESTED:
					// Add project back, including all tasks
					return state
				
				default:
					return state
			}

		
		case GET_ALL:
			return {
				...state,
				count: action.payload.taskgroups.length,
				taskgroups: action.payload.taskgroups
			}
	
		  
	    case ADD:
			return {
				...state,
				count: state.count + 1,
				taskgroups: [...state.taskgroups, action.taskgroup],
			}
	
	    case DELETE:
			return {
				...state,
				taskgroups: [
					...state.taskgroups.filter(t => t.id !== action.id)
				],
				isDeleting: !state.isDeleting
			}
	
	    case DELETE_MULTIPLE:
			return {
				...state,
				taskgroups: [
					...state.taskgroups.filter(t => action.ids.indexOf(t.id) < 0)
				],
				isDeleting: !state.isDeleting
			}
		
	
	

		
		case RENAME:
			return stateWithUpdatedTaskgroup(state, action.id, (taskgroup) => {
					return {
						groupName: action.name
					}
				})
	
	    case SET_START:
			return stateWithUpdatedTaskgroup(state, action.id, (taskgroup) => {
				return {
					lateststartweek: action.lateststartweek,
					lateststartyear: action.lateststartyear,
				}
			})
	
	    case SET_DURATION:
			return stateWithUpdatedTaskgroup(state, action.id, (taskgroup) => {
				return {
					minimumDuration: action.minimumDuration
				}
			})
		
		case CHANGE_TRELLOBOARD_URL:
			return stateWithUpdatedTaskgroup(state, action.id, (taskgroup) => {
					return {
						trelloBoardUrl: action.trelloBoardUrl,
					}
				})
		
	    case SET_FOCUS:
			return stateWithUpdatedTaskgroup(state, action.id, (taskgroup) => {
				return {
					focus: action.focus
				}
			})


		
		// =================
		
		case ASSIGN_TASK:
			return state.taskgroups.find(tg => tg.id === action.id).tasks.indexOf(action.taskid) < 0
				? stateWithUpdatedTaskgroup(state, action.id, (taskgroup) => {
					return {
						tasks: [...taskgroup.tasks, action.taskid]
					}
				})
				: state
		
		
		case REMOVE_TASK:
			return stateWithUpdatedTaskgroup(state, action.id, (taskgroup) => {
					return {
						tasks: taskgroup.tasks.filter(tgid => tgid !== action.taskid)
					}
				})
		
		
		// =================
		
		
	    case UPDATE_NAMEOFFSET:
			return stateWithUpdatedTaskgroup(state, action.id, (taskgroup) => {
				return {
					nameOffsetXpx: action.offsetLeft
				}
			})

		case UPDATE_NAMEOFFSETS:
			// Update taskgroups name offset pixels, if needed
			return {
				...state,
				taskgroups: state.taskgroups.map(taskgroup => {
					const titleEl = document.getElementById(taskgroup.id)
					
					if (!titleEl) return taskgroup

					const titleRect = titleEl.getBoundingClientRect()

					if (titleRect.right < action.viewboxLeft) return taskgroup

					const minOffset = Math.max(action.viewboxLeft - titleRect.left, 0)
					const maxOffset = Math.max(0, titleRect.right - titleRect.left - 90)
					
					const newNameOffsetPixelsX = Math.min(minOffset, maxOffset)
				
					return Object.assign({}, taskgroup, { nameOffsetXpx: newNameOffsetPixelsX })
				})
			}
	
	    default:
			return state
	}
}






export const addTaskgroup = (id, groupname, lateststartweek, lateststartyear, minimumduration) => {
	return dispatch => {
		const taskgroup = createTaskgroup(id, groupname, lateststartweek, lateststartyear, minimumduration)
		
		dispatch({
			type: ADD,
			taskgroup: taskgroup
		})
		
		dispatch(postTaskgroupAPI(taskgroup))
	}
}

export const deleteTaskgroup = (id) => {
	return dispatch => {
		dispatch({
			type: DELETE,
			id: id
		})

		dispatch(deleteTaskgroupAPI({
			id: id
		}))
	}
}

export const deleteTaskgroups = (ids) => {
	return dispatch => {
		dispatch({
			type: DELETE_MULTIPLE,
			ids: ids
		})

		dispatch(deleteTaskgroupsAPI(ids))
	}
}



export const renameTaskgroup = (id, name) => {
	return (dispatch, getState) => {
		dispatch({
			type: RENAME,
			id: id,
			name: name
		})
		
		const taskgroup = getState().taskgroups.taskgroups.find(t => t.id === id)
		dispatch(updateTaskgroupAPI(taskgroup))
	}
}

export const changeTaskgroupTrelloBoardUrl = (id, trelloBoardUrl) => {
	return (dispatch, getState) => {
		dispatch({
			type: CHANGE_TRELLOBOARD_URL,
			id: id,
			trelloBoardUrl: trelloBoardUrl
		})
		
		const taskgroup = getState().taskgroups.taskgroups.find(t => t.id === id)
		dispatch(updateTaskgroupAPI(taskgroup))
	}
}


export const setTaskgroupLatestStart = (id, lateststartweek, lateststartyear) => {
	return (dispatch, getState) => {
		const taskgroup = getState().taskgroups.taskgroups.find(tg => tg.id === id)

		if (taskgroup.lateststartweek !== lateststartweek || taskgroup.lateststartyear !== lateststartyear ) {
			dispatch({
				type: SET_START,
				id: id,
				lateststartweek: lateststartweek,
				lateststartyear: lateststartyear,
			})
			
			const taskgroup = getState().taskgroups.taskgroups.find(t => t.id === id)
			dispatch(updateTaskgroupAPI(taskgroup))
		}
	}
}

export const setTaskgroupMinimumDurationTo = (id, minimumDuration) => {
	return (dispatch, getState) => {
		const taskgroup = getState().taskgroups.taskgroups.find(t => t.id === id)
		
		if (taskgroup.minimumDuration !== minimumDuration) {
			dispatch({
				type: SET_DURATION,
				id: id,
				minimumDuration: minimumDuration
			})
			
			const taskgroup = getState().taskgroups.taskgroups.find(t => t.id === id)
			dispatch(updateTaskgroupAPI(taskgroup))
		}
	}
}


export const setTaskgroupFocus = (id, focusEnabled) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_FOCUS,
			id: id,
			focus: focusEnabled
		})
	}
}


export const setTaskgroupNameOffsetXpx = (id, offsetPixelsX) => {
	return (dispatch, getState) => {
		dispatch({
			type: UPDATE_NAMEOFFSET,
			id: id,
			offsetLeft: offsetPixelsX
		})
	}
}


export const updateTaskgroupsNameOffsetXpxFromViewbox = (viewboxLeft) => {
	return (dispatch, getState) => {
		dispatch({
			type: UPDATE_NAMEOFFSETS,
			viewboxLeft: viewboxLeft
		})
	}
}


// ================


export const assignTaskToTaskgroup = (taskgroupid, taskid) => {
	return (dispatch, getState) => {

		dispatch({
			type: ASSIGN_TASK,
			id: taskgroupid,
			taskid: taskid
		})
		
		const taskgroup = getState().taskgroups.taskgroups.find(tg => tg.id === taskgroupid)
		dispatch(updateTaskgroupAPI(taskgroup))
	}
}


export const removeTaskFromTaskgroup = (taskgroupid, taskid) => {
	return (dispatch, getState) => {
		dispatch({
			type: REMOVE_TASK,
			id: taskgroupid,
			taskid: taskid
		})
		
		const taskgroup = getState().taskgroups.taskgroups.find(tg => tg.id === taskgroupid)
		dispatch(updateTaskgroupAPI(taskgroup))
	}
}


// ================


export const selectTaskgroupWithTask = (taskgroups, taskid) => {
	return taskgroups.find(tg => tg.tasks.indexOf(taskid) !== -1)
}


export const selectTaskIdsByTaskgroup = (taskgroups, id) => {
	return taskgroups.find(tg => tg.id === id).tasks
}



export const selectTaskgroups = (statetaskgroups, taskgroupIds, statetasks, stateresources, filter) => {
	if (!taskgroupIds) {
		return [];
	}
	
	const filterFirstWeek = createWeekObjForWeekId(filter.weeks.first)
	const filterLastWeek = createWeekObjForWeekId(filter.weeks.last)
	
	const taskgroups = taskgroupIds.map(taskgroupid => {
		const taskgroup = statetaskgroups.find(tg => tg.id === taskgroupid)
		if (!taskgroup) {
			return undefined
		}
		
		// Get tasks
		const tasks = selectTasks(statetasks, taskgroup.tasks, stateresources, filter)
		
		if (filter.isResourcesFiltered && tasks.length === 0) {
			return undefined
		}
		
		tasks.sort(compareTasks)
		const masonryObj = masonryTasks(tasks)
		const maxy = getMasonryMaxY([], masonryObj.tasks)

		//console.log("======")
		//console.log("group "+taskgroup.id)
		// Find start week and min duration
		let startWeekObj = masonryObj.tasks.length > 0
						? createWeekObjForWeekAndYear(masonryObj.tasks[0].startweek, masonryObj.tasks[0].startyear)
						: createWeekObjForWeekAndYear(taskgroup.lateststartweek, taskgroup.lateststartyear)
		let duration =  masonryObj.tasks.length > 0
						? masonryObj.tasks[0].duration
						: taskgroup.minimumDuration
		//console.log("start w: "+startWeekObj.number+ ", y: " + startWeekObj.year + ", duration: "+duration)
		masonryObj.tasks.forEach((task) => {
			const taskWeekObj = createWeekObjForWeekAndYear(task.startweek, task.startyear)
			//console.log("- task w: "+taskWeekObj.number+ ", y: " + taskWeekObj.year + ", duration: "+task.duration)
			const weekOffset = calcWeeksDuration(taskWeekObj, startWeekObj)
			const taskIsEarliest = weekOffset < 0
			//console.log("--- compare task earliest: " + taskIsEarliest + ", week offset: "+weekOffset )
			duration = taskIsEarliest
						? Math.max(duration - weekOffset, task.duration)
						: Math.max(duration, task.duration + weekOffset)
			startWeekObj = taskIsEarliest
							? taskWeekObj
							: startWeekObj
		})

		// Create weeks
		const taskgroupFirstWeek = createWeekObjForWeekAndYear(startWeekObj.number, startWeekObj.year)
		const taskgroupLastWeek = calcWeekObjForWeekDuration(startWeekObj.number, startWeekObj.year, duration - 1)
		
		if (compareWeeks(taskgroupLastWeek, filterFirstWeek) === -1
			|| compareWeeks(taskgroupFirstWeek, filterLastWeek) === 1)
				return undefined	// Taskgroup is outside of filter weeks range, so hide it 
		
		const initWeekRange = {
			first: getIdForWeekObj(taskgroupFirstWeek),
			last: getIdForWeekObj(taskgroupLastWeek)
		}
		
		const weeks = {
			first: initWeekRange.first,
			last: initWeekRange.last,
			weeks: createWeekObjArr(initWeekRange.first, initWeekRange.last)
		}


		return Object.assign({}, taskgroup, {
			tasks: masonryObj.tasks,
			maxy: maxy,
			weeks: weeks,
			lateststartweek: startWeekObj.number,
			lateststartyear: startWeekObj.year,
			minimumDuration: duration,
		})
	})
	
	const validTaskgroups = taskgroups.filter(tg => tg !== undefined)
	
	return validTaskgroups
}







export const compareTaskgroups = (taskgroup1, taskgroup2) => {
		const sameweek = taskgroup1.lateststartyear === taskgroup2.lateststartyear && taskgroup1.lateststartweek === taskgroup2.lateststartweek
		return taskgroup1.lateststartyear > taskgroup2.lateststartyear || (taskgroup1.lateststartyear === taskgroup2.lateststartyear && taskgroup1.lateststartweek > taskgroup2.lateststartweek) || (sameweek && taskgroup1.minimumDuration < taskgroup2.minimumDuration)
				? 1
				: sameweek && taskgroup1.minimumDuration === taskgroup2.minimumDuration
					? 0
					: -1
	}


