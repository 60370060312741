import { findIndexOfElWithIntersectingX } from '../helpers/weeks'
import { updateTaskgroupsNameOffsetXpxFromViewbox } from '../reducers/taskgroups';

export const UPDATE_VIEWBOX_DEBOUNCE_TIMER = 'touchstate/UPDATE_VIEWBOX_DEBOUNCE'


const initialState = {
	debounceTimer: -1
}

export default (state = initialState, action) => {
	switch (action.type) {

		case UPDATE_VIEWBOX_DEBOUNCE_TIMER:

			clearTimeout(state.debounceTimer)
			const debounceTimer = setTimeout(action.onDebounceCallback, action.timeoutMs || 0)
			
			return {
				...state,
				debounceTimer: debounceTimer,
			}
		
		default:
			return state
	}
}



export const dispatchViewboxRelatedEvents = (updateImmediately) => {
	return (dispatch, getState) => {
		dispatch({
			type: UPDATE_VIEWBOX_DEBOUNCE_TIMER,
			timeoutMs: updateImmediately ? 0 : 1000,
			onDebounceCallback: () => {
				const _weeks = document.querySelectorAll('thead .bg-container > div')
				if (_weeks && _weeks.length > 0) {
	
					const projectslist = document.querySelector('thead th:first-child').getBoundingClientRect()
					const viewboxOffsetLeftX = projectslist.right
					const viewBoxLeftWeekIndex = Math.min(findIndexOfElWithIntersectingX(_weeks, viewboxOffsetLeftX), _weeks.length - 1)
					const viewBoxLeftWeekEl = _weeks[viewBoxLeftWeekIndex].getBoundingClientRect()
	
					const pxoffset_start = viewboxOffsetLeftX - _weeks[viewBoxLeftWeekIndex].getBoundingClientRect().left
					
					dispatch(updateTaskgroupsNameOffsetXpxFromViewbox(viewBoxLeftWeekEl.left + pxoffset_start))
				}
			}
		})

	}
}
