import { GET_ALL_REQUESTED, GET_ALL, ADD_REQUESTED, ADD, DELETE_REQUESTED, UPDATE_REQUESTED } from '../reducers/resources';
import { apiAction, getUrl } from '../actions/api';
import { METHOD_POST, METHOD_DELETE, METHOD_PUT } from "../middleware/api";




export const fetchResourcesAPI = () => {
	return apiAction({
		url: getUrl("resources"),
		onSuccess: setResources,
		onFailure: () => console.log("Error while fetching resources."),
		label: GET_ALL_REQUESTED
	})
}

export const setResources = (data) => {
	return {
		type: GET_ALL,
		payload: data
	}
}



export const postResourceAPI = (data) => {
	return apiAction({
		url: getUrl("resources"),
		method: METHOD_POST,
		data: data,
		onSuccess: setResource,
		onFailure: () => console.log("Error while posting resource."),
		label: ADD_REQUESTED
	})
}

export const setResource = (data) => {
	return {
		type: ADD,
		payload: data
	}
}


export const deleteResourceAPI = (data) => {
	return apiAction({
		url: getUrl("resources", data.id),
		method: METHOD_DELETE,
		data: data,
		onFailure: () => console.log("Error while putting resource."),
		label: DELETE_REQUESTED
	})
}



export const updateResourceAPI = (data) => {
	return apiAction({
		url: getUrl("resources", data.id),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while updating resource."),
		label: UPDATE_REQUESTED
	})
}



