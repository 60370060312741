import { getIdForDate, iterateWeekAndDuration, createWeekObjForWeekAndYear } from '../helpers/weeks';



function* range(start, end) {
	for (let i = start; i <= end; i++) {
		yield i;
	}
}


export const getMasonryMaxY = (masonryTaskgroups, masonryTasks) => {
	let maxy = 0
	
	masonryTaskgroups.forEach((taskgroup) => {
		maxy = Math.max(maxy, taskgroup.y + taskgroup.maxy + 1)	
	})
	
	masonryTasks.forEach((task) => {
		maxy = Math.max(maxy, task.y)
	})

	return maxy
}




export const masonryTasks = (tasks, masonrymap) => {
	let packedMap = masonrymap || {}
	
	let tempmaxy = 0
	Object.keys(packedMap).forEach((wk) => {
			tempmaxy = Math.max(tempmaxy, packedMap[wk].length)
		})
	tempmaxy += tasks.length
	
	const packedTasks = tasks.map(task => {
			let availy = [...range(0, tempmaxy)]
			
			// Find possible y
			const tweek = createWeekObjForWeekAndYear(task.startweek, task.startyear)
			iterateWeekAndDuration(tweek, task.duration, (wdate) => {
				const wk = getIdForDate(wdate)
				packedMap[wk] = packedMap[wk] || []
				for (let testy = 0; testy < packedMap[wk].length; testy++) {
					if (packedMap[wk][testy] && availy.indexOf(testy) > -1) {
						availy.splice(availy.indexOf(testy), 1)
					}
				}
			})
	
			let y = Math.min(...availy)
	
			// Claim y's
			iterateWeekAndDuration(tweek, task.duration, (wdate) => {
				const wk = getIdForDate(wdate)
				packedMap[wk] = packedMap[wk] || []
				packedMap[wk][y] = true
			})
	
			return Object.assign({}, task, { y: y })
		})
	
	return {
		masonrymap: packedMap, 
		tasks: packedTasks
	}
}



export const masonryTaskgroups = (taskgroups, masonrymap) => {
	let tempmaxy = 0
	
	// Find maxy for creating a y range for packing taskgroups
	taskgroups.forEach(taskgroup => {
			tempmaxy += (taskgroup.maxy || 0) + 2 // + 1 for header + 1 because it counts from 0
		})

	// Pack
	let packedMap = masonrymap || {}
	const packedTaskgroups = taskgroups.map(taskgroup => {
			let availy = [...range(0, tempmaxy)]	// y's not used for duration
			
			// Find possible y for duration
			const tgweek = createWeekObjForWeekAndYear(taskgroup.lateststartweek, taskgroup.lateststartyear)
			iterateWeekAndDuration(tgweek, taskgroup.minimumDuration, (wdate) => {
				const wk = getIdForDate(wdate)
				packedMap[wk] = packedMap[wk] || []
				for (let testy = 0; testy < packedMap[wk].length; testy++) {
					if (packedMap[wk][testy] && availy.indexOf(testy) > -1) {
						availy.splice(availy.indexOf(testy), 1)		// already used y, so should be removed
					}
				}
			})
	
			// Find smallest range of y
			let y = Math.min(...availy)	// Start from the smallest available
			let foundRange = false
			while (!foundRange) {
				let found = true
				for (let testy = y; testy <= y + taskgroup.maxy + 1; testy++) {
					if (availy.indexOf(testy) === -1) {
						found = false
						break
					}
				}
				if (found) {
					foundRange = true
				} else {
					y++
				}
			}
	
			// Claim y's for range
			iterateWeekAndDuration(tgweek, taskgroup.minimumDuration, (wdate) => {
				const wk = getIdForDate(wdate)
				packedMap[wk] = packedMap[wk] || []
				for (let claimy = y; claimy <= y + taskgroup.maxy + 1; claimy++) {
					packedMap[wk][claimy] = true
				}
			})
	
			return Object.assign({}, taskgroup, { y: y })
		})
	
	return {
		masonrymap: packedMap, 
		taskgroups: packedTaskgroups
	}
}