const hslToRgbHex = (h, s, l) => {
		// https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
		h /= 360
		s /= 100
		l /= 100
		let r, g, b
		if (s === 0) {
			r = g = b = l // achromatic
		} else {
			const hue2rgb = (p, q, t) => {
					if (t < 0) t += 1
					if (t > 1) t -= 1
					if (t < 1 / 6) return p + (q - p) * 6 * t
					if (t < 1 / 2) return q
					if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
					return p
				}
			const q = l < 0.5 ? l * (1 + s) : l + s - l * s
			const p = 2 * l - q
			r = hue2rgb(p, q, h + 1 / 3)
			g = hue2rgb(p, q, h)
			b = hue2rgb(p, q, h - 1 / 3)
		}

		const toHex = x => {
				const hex = Math.round(x * 255).toString(16)
				return hex.length === 1 ? '0' + hex : hex
			}

		return `#${toHex(r)}${toHex(g)}${toHex(b)}`
	}
export const selectColor = (colorNum) => {
		const steps = 11
		const colorDeg = 360 / steps
		const deg = colorNum * colorDeg % 360
		const rotation = Math.floor(colorNum / steps) + 1
		const offset = (360 / rotation * 2) + ((colorNum % steps) * colorDeg)
		const s = 30 + (colorNum * 23 % 40)
		const l = 25 + (colorNum * 51 % 30)

		return hslToRgbHex((deg + offset) % 360, s, l)
	}