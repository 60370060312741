import React, { useState, useRef } from 'react';
import useDragTask from '../hooks/usedragtask';
import useExtendTask from '../hooks/useextendtask';
import { TASKGROUP } from '../hooks/usedroptask'
import { connect } from 'react-redux';
import { setTaskgroupLatestStart, setTaskgroupMinimumDurationTo } from '../reducers/taskgroups';
import { createWeekObjForWeekAndYear, calcWeeksDuration, calcWeekObjForWeekDuration } from '../helpers/weeks';
import ProjectTask from '../components/project-task'
import ProjectTaskgroupName from '../components/project-taskgroup-name'
import WeekDroptarget from '../components/week-droptarget'


const Taskgroup = ({ taskgroup, project, weeks, touchstate, dispatch }) => {
	//console.log("render taskgroup")


	const _container = useRef(null)
	const _taskgroup = useRef(null)
	


	const startdrag = (ev) => {
			_container.current.classList.add('is-dragging')
			document.body.classList.add('drag-taskgroup')
			
			console.log("drag task group "+taskgroup.id)
			ev.dataTransfer.setData("text/plain", "Taskgroup " + taskgroup.id)
			ev.dataTransfer.setData("id", taskgroup.id)
			ev.dataTransfer.setData("prjid", project.id)
			ev.dataTransfer.setData("source", TASKGROUP)

			const crect = _container.current.getBoundingClientRect()
			const graboffsetx = ev.clientX - crect.x
			const trect = _taskgroup.current.getBoundingClientRect()
			const padding = crect.width - trect.width
			ev.dataTransfer.setData("xoffset", graboffsetx)
			ev.dataTransfer.setData("xpadding", padding)
		}
	const enddrag = (ev) => {
			_container.current.classList.remove('is-dragging')
			document.body.classList.remove('drag-taskgroup')
			setTimeout(() => document.body.classList.remove('drag-taskgroup'), 20)
			console.log("dropped task group "+taskgroup.id)
		}
	const dragtaskgroup = useDragTask( startdrag, enddrag, TASKGROUP, undefined, undefined, 500)




	const [unitwidth, setUnitwidth] = useState(0)
	const [taskgroupStartWidthPx, setTaskgroupStartWidthPx] = useState(0)
	
	const startExtend = () => {
			if (_container.current === null) return
			
			_container.current.classList.add('is-extending')
			const width = _container.current.clientWidth
			const uwidth = Math.round(width / taskgroup.minimumDuration)

			setTaskgroupStartWidthPx(width)
			setUnitwidth(uwidth)
		}
	const stopExtend = () => {
			//console.log("Stop extend")
			if (_container.current === null) return

			_container.current.classList.remove('is-extending')
			const marginleft = parseInt(_container.current.style.marginLeft)
			const offsetStart = Math.floor( marginleft / unitwidth )
			const newWeek = calcWeekObjForWeekDuration(taskgroup.lateststartweek, taskgroup.lateststartyear, offsetStart)

			const width = parseInt( _container.current.style.width ) - (offsetStart * unitwidth - marginleft)
			const newDuration = Math.max(1, Math.round( width / unitwidth ))

			dispatch(setTaskgroupLatestStart(taskgroup.id, newWeek.number, newWeek.year))
			
			dispatch(setTaskgroupMinimumDurationTo(taskgroup.id, newDuration))
			
			// reset styles for cases where start and duration has not changed and triggerede a redraw
			setStyles(_container, 0, newDuration * unitwidth)
		}
	
	const setStyles = (_container, left, width) => {
			_container.current.style.marginLeft = left + "px"
			_container.current.style.width = width + "px"
		}
	const setExtendWidth = (_container, pxoffset, widthoffset) => {
			setStyles(_container, 
				Math.min(pxoffset, taskgroupStartWidthPx - unitwidth),
				Math.max(unitwidth, taskgroupStartWidthPx + widthoffset))
		}
	const extendLeft = (ev, pos) => {
			if (_container.current === null) return

			setExtendWidth(_container, pos.x, -pos.x)
		}
	const extendRight = (ev, pos) => {
			if (_container.current === null) return

			setExtendWidth(_container, 0, pos.x)
		}



	const extendtaskleft = useExtendTask( startExtend, stopExtend, extendLeft, taskgroup, true, true )
	const extendtaskright = useExtendTask( startExtend, stopExtend, extendRight, taskgroup, true, true )



	const unitWidthRem = 6
	const calcWidth = (units) => {
			return unitWidthRem * units
		}
	const taskgroupWidthRem = calcWidth(taskgroup.minimumDuration)

	const marginAbove = .5
	const marginBetween = .25
	const padding = 0
	const lineHeight = 1.75

	const width = taskgroupWidthRem + 'rem'
	const wrapperWidth = (taskgroupWidthRem - marginBetween) + 'rem'
	const left = calcWeeksDuration(createWeekObjForWeekAndYear(taskgroup.lateststartweek, taskgroup.lateststartyear), weeks.first) * unitWidthRem + 'rem'
	const unitheight = project.isCompacted
						? .25
						: lineHeight + padding
	const top = (marginAbove + ((taskgroup.y || 0) * (unitheight + marginBetween))) + 'rem'
	const height = ((taskgroup.maxy + 2) * (unitheight + marginBetween) - marginBetween) + 'rem'

	const tasksTopOffset = (unitheight - (2 * marginBetween)) + 'rem'




	const focusClass = taskgroup.focus
				? "is-focus"
				: ""


	const opacity = taskgroup.currentSavingOps > 0
						? .75
						: 1


	if (!touchstate.isEnabled) {
		return (
				<div 
					ref={_container}
					id={taskgroup.id}
					className={"taskgroup-container " + focusClass} 
					style={{ width: width, top: top, left: left, opacity: opacity }}>
					<div ref={_taskgroup} 
						className={ "taskgroup " + (project.isCompacted ? "compacted" : "") }
						style={{ height: height }}
						><ProjectTaskgroupName taskgroup={taskgroup} />
							<div className="bg-container-wrapper" style={{ width: wrapperWidth }}><div className="bg-container" style={{ width: width }}>{ 
								taskgroup.weeks.weeks.map((week, index) =>
										<WeekDroptarget key={week.id} targettype={TASKGROUP} taskgroup={taskgroup} project={project} week={week} />
									)
							}</div></div>
							<div className="tasks-container" style={{ top: tasksTopOffset }}>{
								taskgroup.tasks.map((task, index) =>
										<ProjectTask key={task.id} taskgroup={taskgroup} project={project} task={task} weeks={taskgroup.weeks} />
									)
							}</div>
					</div>
				</div>
			)
	}


	return (
		<div className={"taskgroup-container " + focusClass} style={{ width: width, top: top, left: left, opacity: opacity }} ref={_container}>
			<div ref={_taskgroup} 
				draggable
				className={ "taskgroup " + (project.isCompacted ? "compacted" : "") }
				style={{ height: height }}
				{...dragtaskgroup}
				><span className="extend" {...extendtaskleft}></span><ProjectTaskgroupName taskgroup={taskgroup} />
					<div className="bg-container-wrapper" style={{ width: wrapperWidth }}><div className="bg-container" style={{ width: width }}>{ 
						taskgroup.weeks.weeks.map((week, index) =>
								<WeekDroptarget key={week.id} targettype={TASKGROUP} taskgroup={taskgroup} project={project} week={week} />
							)
					}</div></div>
					<div className="tasks-container" style={{ top: tasksTopOffset }}>{
						taskgroup.tasks.map((task, index) =>
								<ProjectTask key={task.id} taskgroup={taskgroup} project={project} task={task} weeks={taskgroup.weeks} />
							)
					}</div><span className="extend" {...extendtaskright}></span>
			</div></div>
	)
}

const mapStateToProps = ({ weeks, touchstate }, { taskgroup, project }) => ({
	weeks,
	touchstate,
	taskgroup,
	project
})


export default connect(mapStateToProps)(Taskgroup);