import { useState, useEffect, useCallback } from 'react'

export default function useDragTask(dragStartCallback = () => {}, dragEndCallback = () => {}, dragType = "", clickCallback = () => {}, longClickCallback = () => {}, ms = 300, watch = {}, allowDragCheck = () => { return true }) {	
	const [startLongClick, setStartLongClick] = useState(false)
	const [isDragging, setIsDragging] = useState(false)
	const [startClickTime, setStartClickTime] = useState(new Date())
	const [eventFired, setEventFired] = useState(false)
	
	useEffect(() => {
		let timerId
		if (startLongClick) {
			timerId = setTimeout(longClickCallback, ms)
		} else {
			clearTimeout(timerId)
		}
		
		return () => {
			clearTimeout(timerId)
		}
	}, [startLongClick])


	const dragStart = useCallback((ev) => {
		ev.stopPropagation()
		
		setStartLongClick(false)

		if (!allowDragCheck(ev)) {
			return
		}
		
		ev.dataTransfer.setData("type", dragType)
		ev.dataTransfer.setData(dragType, "type")	// dragOver hack: can not read data values on dragOver -- only the keys
		
		setIsDragging(true)
		dragStartCallback(ev)
	}, [watch])
	
	const start = useCallback((ev) => {
		ev.stopPropagation()
		setStartLongClick(true)
		setStartClickTime(new Date())
		setEventFired(false)
	}, [])
	const stop = useCallback((ev) => {
		setStartLongClick(false)
		if (eventFired) {
			return
		}
		
		setEventFired(true)
		if (isDragging) {
			setIsDragging(false)
			dragEndCallback(ev)
		} else if (new Date() - startClickTime < ms) {
			clickCallback(ev)
		}
	}, [isDragging, watch])
	
	return {
		onMouseDown: start,
		onMouseUp: stop,
		onMouseLeave: stop,
		onTouchStart: start,
		onTouchEnd: stop,
		onDragStart: dragStart,
	}
}
