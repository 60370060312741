import { createWeekObjArr, getIdForDate } from '../helpers/weeks'


export const ADD_BEFORE = 'week/ADD_BEFORE'
export const ADD_AFTER = 'week/ADD_AFTER'




const initWeekRange = {
	first: getIdForDate( new Date().setDate(new Date().getDate() - (3 * 7) ) ),	// 3 weeks back
	last: getIdForDate( new Date().setDate(new Date().getDate() + 52 * 7 ) )	// 12 months forward
}

const initialState = {
	first: initWeekRange.first,
	last: initWeekRange.last,
	weeks: createWeekObjArr(initWeekRange.first, initWeekRange.last)
}






export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BEFORE:
      return {
        ...state,
        first: state.first - (action.weeks || 3),
        weeks: createWeekObjArr(state.first - action.weeks, state.first - 1).concat(...state.weeks),
      }

    case ADD_AFTER:
      return {
        ...state,
        last: state.last + (action.weeks || 3),
        weeks: state.weeks.concat(createWeekObjArr(state.last + 1, state.last + action.weeks)),
      }

    default:
      return state
  }
}

export const addWeekBefore = (weeks) => {
  return dispatch => {
    dispatch({
      type: ADD_BEFORE,
      weeks: weeks
    })
  }
}

export const addWeekAfter = (weeks) => {
  return dispatch => {
    dispatch({
      type: ADD_AFTER,
      weeks: weeks
    })
  }
}
