import React, { useRef } from 'react';
import useDropTask, { TASKGROUP, TASK, RESOURCE, PROJECT } from '../hooks/usedroptask'
import { connect } from 'react-redux';
import { removeTaskFromProject, assignTaskToProject, removeTaskgroupFromProject, assignTaskgroupToProject } from '../reducers/projects'
import { newTaskgroupCID, newDefaultTaskgroupName, addTaskgroup, removeTaskFromTaskgroup, assignTaskToTaskgroup, setTaskgroupLatestStart } from '../reducers/taskgroups';
import { setTaskStart, newCID, addTask, offsetTaskStart } from '../reducers/tasks'
import { selectResource } from '../reducers/resources'
import { calcWeekObjForWeekDuration, createWeekObjForWeekAndYear, calcWeeksDuration } from '../helpers/weeks';


const WeekDroptarget = ({ touchstate, targettype, taskgroup, project, resources, taskgroups, week, dispatch }) => {
	const _week = useRef(null)
	const projectId = project.id
	const taskgroupId = taskgroup !== undefined
						? taskgroup.id
						: ""

	const onDrop = (ev) => {
			document.body.classList.remove('drag-task')
			document.body.classList.remove('drag-taskgroup')

			const payloadtype = ev.dataTransfer.getData("type")
			const id = ev.dataTransfer.getData("id")
			switch (payloadtype) {
				case TASK:
				case TASKGROUP:
					const oldprojectid = parseInt(ev.dataTransfer.getData("prjid") || "-1")
					const differentProject = oldprojectid !== -1 && oldprojectid !== projectId
					
					const oldtaskgroupid = ev.dataTransfer.getData("tskgid") || ""
					const differentTaskgroup = oldtaskgroupid !== "" && oldtaskgroupid !== taskgroupId

					const sourcetype = ev.dataTransfer.getData("source") || ""

					const differentSourcetype = sourcetype !== targettype

					// remove from source
					switch (payloadtype) {
						case TASK:
							switch(sourcetype) {
								case TASKGROUP:
									if (differentSourcetype || differentTaskgroup) {
										dispatch(removeTaskFromTaskgroup(oldtaskgroupid, id))
									}
									break;
									
								case PROJECT:
									if (differentSourcetype || differentProject) {
										dispatch(removeTaskFromProject(oldprojectid, id))
									}
									break
								
								default: break
							}
							break

						case TASKGROUP:
							if (differentProject) {
								dispatch(removeTaskgroupFromProject(oldprojectid, id))
							}
							break
					
						default: break
					}


					
					// assign to task group or project
					switch (payloadtype) {
						case TASK:
							switch (targettype) {
								case TASKGROUP:
									if (differentSourcetype || differentTaskgroup) {
										dispatch(assignTaskToTaskgroup(taskgroupId, id))
									}
									break;
								
								case PROJECT:
									if (differentSourcetype || differentProject) {
										dispatch(assignTaskToProject(projectId, id))
									}
									break;
								
								default: break
							}
							break

						case TASKGROUP:
							if (differentProject) {
								dispatch(assignTaskgroupToProject(projectId, id))
							}
							break
							
						default: break
					}
		
					// Calculate week number
					const padding =	parseInt( ev.dataTransfer.getData("xpadding") )
					const graboffsetx = parseInt( ev.dataTransfer.getData("xoffset") ) - (padding / 2)
					const rect = _week.current.getBoundingClientRect()
					const unitwidth = rect.width
					const dropoffsetx = ev.clientX - rect.x
					const offsetweeks = Math.round((graboffsetx - dropoffsetx) / unitwidth)
					const newWeek = calcWeekObjForWeekDuration(week.number, week.year, -offsetweeks)
					
					switch (payloadtype) {
						case TASK:
							dispatch(setTaskStart(id, newWeek.number, newWeek.year))
							break
							
						case TASKGROUP:
							const payloadtaskgroup = taskgroups.find(tg => tg.id === id);
							if (payloadtaskgroup === undefined) {
								throw new Error("Task group not found")
							}
							
							const oldWeek = createWeekObjForWeekAndYear(payloadtaskgroup.lateststartweek, payloadtaskgroup.lateststartyear)
							const movedweeks = calcWeeksDuration(newWeek, oldWeek)
							payloadtaskgroup.tasks.forEach((taskid) => {
								dispatch(offsetTaskStart(taskid, movedweeks))
							})
							dispatch(setTaskgroupLatestStart(id, newWeek.number, newWeek.year))
							break

							default: break
					}
					break

					
				case RESOURCE:
					const resource = selectResource(resources, id)
					const taskId = newCID(resource);
					dispatch(addTask(taskId, resource.id, week.number, week.year, 1))
					switch (targettype) {
						case TASKGROUP:
							dispatch(assignTaskToTaskgroup(taskgroupId, taskId))
							break;
						
						case PROJECT:
							dispatch(assignTaskToProject(projectId, taskId))
							break;
							
						default: break
					}
					break
				
				default: break
			}
		}

	const droptask = useDropTask(undefined, undefined, onDrop, [TASKGROUP, TASK, RESOURCE], resources)


	const createTaskGroup = targettype === PROJECT
						? (ev) => {
								if (touchstate.isEnabled) {
									console.log("Create task group for week " + week.number + " in " + week.year + " on project " + project.id)
									const taskgroupId = newTaskgroupCID(project)
									const taskgroupName = newDefaultTaskgroupName()
									dispatch(addTaskgroup(taskgroupId, taskgroupName, week.number, week.year, 1))
									dispatch(assignTaskgroupToProject(project.id, taskgroupId))
								}
							}
						: undefined


	return <div key={week.id} ref={_week} {...droptask} onDoubleClick={createTaskGroup} className={"project-bg " + (week.beforenow && !project.colorBg ? "before-week" : "") + " " + (week.now ? "this-week" : "") }></div>
}



const mapStateToProps = ({ touchstate, resources, taskgroups }, { project, taskgroup, week, targettype }) => ({
	touchstate,
	resources: resources.resources,
	taskgroups: taskgroups.taskgroups,
	project,
	taskgroup,
	week,
	targettype
})


export default connect(mapStateToProps)(WeekDroptarget)