import React, { useRef } from 'react';
import { connect } from 'react-redux';
import useMouseKeyTimeout from '../hooks/usemousekeytimeout'
import { dispatchViewboxRelatedEvents } from '../reducers/viewboxChange';

const WeekNumbers = ({ weeks, dispatch }) => {

    const _container = useRef(null)
    
	const scrollToThisWeek = () => {
			console.log("scroll to this week")
			
			const _thisweek = _container.current.querySelector('.this-week')
			if (_thisweek) {
				const thisweek = _thisweek.getBoundingClientRect()
				const projectlist = document.querySelector('thead th:first-child').getBoundingClientRect()

				const isAndroid = document.getElementsByTagName('html')[0].className.indexOf('android') !== -1
				const container = isAndroid
								? document.getElementsByTagName('main')[0]
								: window
				const currx = container.scrollX
				const curry = container.scrollY
				const leftpadding = 60

				container.scroll({
					top: curry,
					left: currx + (thisweek.x - ((projectlist.width || 0) + leftpadding)),
					behavior: 'smooth'
				})
				setTimeout(() => {
					dispatch(dispatchViewboxRelatedEvents()) 
				}, 800)
			}
		} 

	useMouseKeyTimeout( scrollToThisWeek, undefined, 10 )


	const unitWidthRem = 6;
	const width = unitWidthRem * weeks.weeks.length + 'rem'
	
	return (
		<th><div ref={_container} className="bg-container" style={{width: width, marginLeft: "-1px" }}>{
			weeks.weeks.map((week, index) => {
				return <div key={week.id} className={"week-bg " + (week.beforenow ? "before-week" : "") + " " + (week.now ? "this-week" : "") }>{week.number}</div>
			} )
		}</div></th>
	)
}


export default connect()(WeekNumbers)
