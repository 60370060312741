import { useState, useEffect } from 'react'

export default function useMouseKeyTimeout( onTimeoutCallback = () => {}, onFirstResetCallback = () => {}, waitSec = 5, watch = {} ) {	
	const FIRSTRESET = "mousetimer/FIRSTRESET"
	const TIMEOUT = "mousetimer/TIMEOUT"
	const [timeoutState, setTimeoutState] = useState(FIRSTRESET)
	const [mouseMoveOrKeypressTimer, setMouseMoveOrKeypressTimer] = useState(-1)

	const waitMs = waitSec * 1000

	const resetTimeout = () => {
		clearTimeout(mouseMoveOrKeypressTimer)
		const newMouseMoveOrKeypressTimer = setTimeout(() => {
			//console.log("useMouseKeyTimer Time out")
			setTimeoutState(TIMEOUT)
			onTimeoutCallback()
		}, waitMs)
		
		//console.log("useMouseKeyTimer Setting timer (" + mouseMoveOrKeypressTimer + " to " + newMouseMoveOrKeypressTimer + ")")
		setMouseMoveOrKeypressTimer(newMouseMoveOrKeypressTimer)
	}


	useEffect(() => {
		//console.log("useMouseKeyTimer Mounting (" + mouseMoveOrKeypressTimer + ")")

		const resetTimeoutStateFromEvent = (ev) => {
			if (timeoutState === TIMEOUT) {
				//console.log("useMouseKeyTimer First reset")
				setTimeoutState(FIRSTRESET)
				onFirstResetCallback()
			}

			resetTimeout()
		}
		
		if (mouseMoveOrKeypressTimer === -1) {
			//console.log("useMouseKeyTimer init")
			resetTimeout()
		}
		
		window.addEventListener('mousemove', resetTimeoutStateFromEvent)
		window.addEventListener('keyup', resetTimeoutStateFromEvent)
		
		return () => {
			//console.log("useMouseKeyTimer Unmounting (" + mouseMoveOrKeypressTimer + ")")
			window.removeEventListener('mousemove', resetTimeoutStateFromEvent)
			window.removeEventListener('keyup', resetTimeoutStateFromEvent)
			clearTimeout(mouseMoveOrKeypressTimer)
		}
	}, [timeoutState, mouseMoveOrKeypressTimer, watch])
}
