import React from 'react';
import { connect } from 'react-redux';
import { addTag } from '../reducers/tags';

const TagAddButton = ({ next, maxlength, dispatch }) => {
	let input
	let inputProps = {}
	
	if (maxlength)
		inputProps.maxLength = maxlength
	
	const submit = (ev) => {
			ev.preventDefault()
			if (!input.value.trim()) {
				return
			}
			dispatch(addTag(input.value, next + 1))
			input.value = ''
		}

	return (
		<form onSubmit={submit} className="hide-touch-disabled">
			<input ref={node => (input = node)} className="inline margin-top" type="text" placeholder="Indtast mærke" {...inputProps} />
			<button type="submit" className="inline margin-top margin-half-left">+</button>
		</form>
	)
}
	
export default connect()(TagAddButton);