import React, { useState, useRef } from 'react';
import useDragTask from '../hooks/usedragtask';
import useExtendTask from '../hooks/useextendtask';
import { TASKGROUP, TASK, PROJECT } from '../hooks/usedroptask';
import { connect } from 'react-redux';
import { setTaskStart, setTaskDurationTo } from '../reducers/tasks';
import { createWeekObjForWeekAndYear, calcWeeksDuration, calcWeekObjForWeekDuration } from '../helpers/weeks';


const Task = ({ task, taskgroup, project, weeks, dispatch }) => {

	const _container = useRef(null)
	const _task = useRef(null)
	


	const startdrag = (ev) => {
			_container.current.classList.add('is-dragging')
			document.body.classList.add('drag-task')
			
			console.log("drag task "+task.id)
			ev.dataTransfer.setData("text/plain", "Task " + task.id)
			ev.dataTransfer.setData("id", task.id)
			const source = taskgroup
							? TASKGROUP
							: PROJECT
			ev.dataTransfer.setData("source", source)
			ev.dataTransfer.setData("prjid", project.id)
			
			if (taskgroup) {
				ev.dataTransfer.setData("tskgid", taskgroup.id)
			}

			const crect = _container.current.getBoundingClientRect()
			const graboffsetx = ev.clientX - crect.x
			const trect = _task.current.getBoundingClientRect()
			const padding = crect.width - trect.width
			ev.dataTransfer.setData("xoffset", graboffsetx)
			ev.dataTransfer.setData("xpadding", padding)
		}
	const enddrag = (ev) => {
			_container.current.classList.remove('is-dragging')
			document.body.classList.remove('drag-task')
			setTimeout(() => document.body.classList.remove('drag-task'), 20)
			console.log("dropped task "+task.id)
		}
	const dragtask = useDragTask( startdrag, enddrag, TASK, undefined, undefined, 500)



	const [unitwidth, setUnitwidth] = useState(0)
	const [taskStartWidthPx, setTaskStartWidthPx] = useState(0)
	
	const startExtend = () => {
			if (_container.current === null) return
			
			_container.current.classList.add('is-extending')
			const width = _container.current.clientWidth
			const uwidth = Math.round(width / task.duration)

			setTaskStartWidthPx(width)
			setUnitwidth(uwidth)
		}
	const stopExtend = () => {
			//console.log("Stop extend")
			if (_container.current === null) return

			_container.current.classList.remove('is-extending')
			const marginleft = parseInt(_container.current.style.marginLeft)
			const offsetStart = Math.floor( marginleft / unitwidth )
			const newWeek = calcWeekObjForWeekDuration(task.startweek, task.startyear, offsetStart)

			const width = parseInt( _container.current.style.width ) - (offsetStart * unitwidth - marginleft)
			const newDuration = Math.max(1, Math.round( width / unitwidth ))

			dispatch(setTaskStart(task.id, newWeek.number, newWeek.year))
			
			dispatch(setTaskDurationTo(task.id, newDuration))
			
			// reset styles for cases where start and duration has not changed and triggerede a redraw
			setStyles(_container, 0, newDuration * unitwidth)
		}
	
	const setStyles = (_container, left, width) => {
			_container.current.style.marginLeft = left + "px"
			_container.current.style.width = width + "px"
		}
	const setExtendWidth = (_container, pxoffset, widthoffset) => {
			setStyles(_container, 
				Math.min(pxoffset, taskStartWidthPx - unitwidth),
				Math.max(unitwidth, taskStartWidthPx + widthoffset))
		}
	const extendLeft = (ev, pos) => {
			if (_container.current === null) return

			setExtendWidth(_container, pos.x, -pos.x)
		}
	const extendRight = (ev, pos) => {
			if (_container.current === null) return

			setExtendWidth(_container, 0, pos.x)
		}



	const extendtaskleft = useExtendTask( startExtend, stopExtend, extendLeft, task, true, true )
	const extendtaskright = useExtendTask( startExtend, stopExtend, extendRight, task, true, true )



	const unitWidthRem = 6
	const calcWidth = (units) => {
			return unitWidthRem * units
		}
	const taskWidthRem = calcWidth(task.duration)

	const marginAbove = .5
	const marginBetween = .25
	const padding = 0
	const lineHeight = 1.75

	const width = taskWidthRem + 'rem'
	const left = calcWeeksDuration(createWeekObjForWeekAndYear(task.startweek, task.startyear), weeks.first) * unitWidthRem + 'rem'
	const unitheight = project.isCompacted
						? .25
						: lineHeight + padding
	const top = (marginAbove + (task.y * (unitheight + marginBetween))) + 'rem'

	const label = project.isCompacted
						? ''
						: task.resource.name

	const opacity = task.currentSavingOps > 0
						? .75
						: 1

	return (
		<div className="task-container" style={{ width: width, top: top, left: left, opacity: opacity }} ref={_container}>
			<div ref={_task} 
				draggable
				className="task"
				style={{ backgroundColor: task.resource.colorBg, color: task.resource.colorTxt, height: unitheight + 'rem' }}
				{...dragtask}
				><span className="extend" {...extendtaskleft}></span>{label}<span className="extend" {...extendtaskright}></span></div></div>
	);
}



export default connect()(Task);