import React from 'react'
import { connect } from 'react-redux'
import { addResource } from '../reducers/resources'

const ResourceAddButton = ({ next, maxlength, dispatch }) => {
	let input
	let inputProps = {}
	
	if (maxlength)
		inputProps.maxLength = maxlength
	
	const submit = (ev) => {
			ev.preventDefault()
			if (!input.value.trim()) {
				return
			}
			dispatch(addResource(input.value, next + 1))
			input.value = ''
		}

	return (
		<form onSubmit={submit}>
			<input ref={node => (input = node)} className="margin-top margin-top margin-half-bottom" type="text" placeholder="Initial" {...inputProps} />
			<button type="submit">+</button>
		</form>
	)
}

export default connect()(ResourceAddButton)