import React from 'react';
import { connect } from 'react-redux';
import { changeEditState } from '../reducers/tags';

const TagMenuButton = ({ tags, dispatch }) => {
	
	const showClass = tags.showingTagsMenu
					? "showing-tag-menu"
					: "";

	const toggle = (ev) => {
			ev.preventDefault();
			dispatch(changeEditState())
		}
	
	return (
		<button 
			type="button" 
			className={'toggle-tags margin-half-left hide-in-presentation show-touch-disabled ' + showClass}
			onClick={toggle}
		>Toggle Tags</button>
	);
}
	
export default connect()(TagMenuButton);