import React from 'react'
import { connect } from 'react-redux'
import Tag from '../components/tag'


const ProjectTagList = ({ project, tags, filter }) => {
	return (
		<div className="tags-container">
		{
			tags.map((tag, index) =>
					<Tag tag={tag} project={project} filtered={filter.isTagsFiltered} selectable={true} key={tag.id} />
				)
		}
		</div>
	)
}

export default connect()(ProjectTagList)
