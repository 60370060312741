import { GET_ALL_REQUESTED, GET_ALL, ADD_REQUESTED, ADD, DELETE_REQUESTED, UPDATE_REQUESTED } from '../reducers/tasks';
import { apiAction, getUrl } from '../actions/api';
import { METHOD_POST, METHOD_DELETE, METHOD_PUT } from "../middleware/api";




export const fetchTasksAPI = () => {
	return apiAction({
		url: getUrl("tasks"),
		onSuccess: setTasks,
		onFailure: () => console.log("Error while fetching tasks."),
		label: GET_ALL_REQUESTED
	})
}

export const setTasks = (data) => {
	return {
		type: GET_ALL,
		payload: data
	}
}



export const postTaskAPI = (data) => {
	return apiAction({
		url: getUrl("tasks"),
		method: METHOD_POST,
		data: data,
		onSuccess: setTask,
		onFailure: () => console.log("Error while posting task."),
		label: ADD_REQUESTED
	})
}

export const setTask = (data) => {
	return {
		type: ADD,
		payload: data
	}
}


export const deleteTaskAPI = (data) => {
	return apiAction({
		url: getUrl("tasks", data.id),
		method: METHOD_DELETE,
		data: data,
		onFailure: () => console.log("Error while deleting task."),
		label: DELETE_REQUESTED
	})
}

export const deleteTasksAPI = (data) => {
	return apiAction({
		url: getUrl("tasks", "deletemultiple"),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while deleting task."),
		label: DELETE_REQUESTED
	})
}

export const deleteTasksByResourceAPI = (data) => {
	return apiAction({
		url: getUrl("tasks", "removeresource/" + data),
		method: METHOD_DELETE,
		onFailure: () => console.log("Error while deleting tasks."),
		label: DELETE_REQUESTED
	})
}



export const updateTaskAPI = (data) => {
	return apiAction({
		url: getUrl("tasks", data.id),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while updating task."),
		label: UPDATE_REQUESTED
	})
}





