import { useCallback } from 'react';

export const TASKGROUP = 'dragtype/TASKGROUP'
export const TASK = 'dragtype/TASK'
export const TAG = 'dragtype/TAG'
export const PROJECT = 'dragtype/PROJECT'
export const RESOURCE = 'dragtype/RESOURCE'

const isValidDragItem = (ev, types) => {
		// dragOver and dragLeave do not have access to dataTransfer values, only keys, so we store the type as the key instead of the value
		const dragtypes = ev.dataTransfer.types
		if (dragtypes.indexOf('type') < 0) return false		// Check if it is a valid drag item, defined as one with the dataTransfer key "type" defined
		if (types.length === 0) return true
		
		for (let i = 0; i < types.length; i++) {
			if (dragtypes.indexOf(types[i].toLowerCase()) >= 0) return true
		}
		
		return false
	}

export default function useDropTask(dragOverCallback = () => {}, dragLeaveCallback = () => {}, dropCallback = () => {}, types = [], watch = {}) {
	const dragOver = useCallback((ev) => {
		ev.preventDefault()
		if (typeof dragOverCallback === 'function' && isValidDragItem(ev, types)) {
			dragOverCallback(ev)
		}
	}, [types, watch])
	
	const dragLeave = useCallback((ev) => {
		ev.preventDefault()
		if (typeof dragLeaveCallback === 'function' && isValidDragItem(ev, types)) {
			dragLeaveCallback(ev)
		}
	}, [types, watch])
	
	const drop = useCallback((ev) => {
		const type = ev.dataTransfer.getData("type")
		if (dropCallback && (types.length === 0 || types.indexOf(type) >= 0)) {
			dropCallback(ev)
		}
	}, [types, watch])
	
	const cancel = useCallback((ev) => {
		ev.preventDefault()
	}, [])
	
	return {
		onDragEnter: cancel,
		onDragOver: dragOver,
		onDragLeave: dragLeave,
		onDrop: drop
	}
}