import React from 'react'
import { Link } from 'react-router-dom'

const Guide = () => (
  <div>
    <header>
      <Link to="/"><h2>Ressourceplanlægger</h2></Link>
      <Link to="/guide">Help</Link>
    </header>
    <h1>Sådan bruger du ressourceplanlæggeren</h1>
    <p>Træk og slip</p>
  </div>
)

export default Guide
